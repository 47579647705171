import React from 'react';
import { string } from 'prop-types';

export const Logo = ({ logoSrc, clientShortName, homePageUrl }) => {
    return (
        <a className="app-header__logo" href={homePageUrl || '#'}>
            {logoSrc && (
                <img
                    src={logoSrc}
                    alt={'logo ' + clientShortName}
                    className="app-header__logo__image"
                />
            )}
        </a>
    );
};

Logo.propTypes = {
    logoSrc: string,
    homePageUrl: string,
    clientShortName: string.isRequired,
};

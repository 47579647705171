import { array, bool, func, string } from 'prop-types';
import React from 'react';

import { SearchItem } from '../../../../components/SearchItem';
import { Spinner } from '../../../../components/Spinner';
import { NoContent } from '../../../../components/NoContent';
import { MESSAGES } from '../../../../constants/messages';

import { isEmpty, map } from 'lodash';

const SearchItems = ({
    results,
    loadingResults,
    noResultsReason,
    redirectCall,
}) => {
    return (
        <div className="col-12 col-lg-8 col-xl-9">
            <div className="search-items">
                {loadingResults ? (
                    <Spinner />
                ) : (
                    <React.Fragment>
                        {isEmpty(results) ? (
                            <NoContent
                                text={
                                    noResultsReason === 'QUERY'
                                        ? MESSAGES.QUERY_NOT_FOUND
                                        : MESSAGES.SEARCH_RESULTS_NOT_FOUND
                                }
                            />
                        ) : (
                            <React.Fragment>
                                {map(
                                    results,
                                    ({
                                        id,
                                        title,
                                        description,
                                        url,
                                        type,
                                        categories,
                                        originalUrl,
                                        eFormUri,
                                        hasAttachments
                                    }) => {
                                        return (
                                            <SearchItem
                                                key={id}
                                                id={id}
                                                title={title}
                                                articleType={type}
                                                description={description}
                                                url={url}
                                                categories={categories}
                                                redirectCall={redirectCall}
                                                originalUrl={originalUrl}
                                                eFormUri={eFormUri}
                                                hasAttachments={hasAttachments}
                                            />
                                        );
                                    }
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

SearchItems.propTypes = {
    results: array.isRequired,
    noResultsReason: string,
    loadingResults: bool,
    redirectCall: func.isRequired,
};

export default SearchItems;

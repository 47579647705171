const prefix = '[SERVICE]';

export const GET_CLIENT_CONFIG = `${prefix} GET_CLIENT_CONFIG`;
export const GET_CATEGORIES_REQUEST = `${prefix} GET_CATEGORIES_REQUEST`;
export const GET_CATEGORIES_SUCCESS = `${prefix} GET_CATEGORIES_SUCCESS`;
export const GET_SERVICE_CARD_SUCCESS = `${prefix} GET_SERVICE_CARD_SUCCESS`;
export const GET_SERVICE_CARD_NOT_FOUND = `${prefix} GET_SERVICE_CARD_NOT_FOUND`;
export const GET_SERVICE_CARD_REQUEST = `${prefix} GET_SERVICE_CARD_REQUEST`;
export const GET_CATEGORIES_LIST_ID = `${prefix} GET_CATEGORIES_LIST_ID`;
export const GET_CATEGORIES_LIST_FULFILLED = `${prefix} GET_CATEGORIES_LIST_FULFILLED`;
export const GET_SUBCATEGORIES_LIST_REQUEST = `${prefix} GET_SUBCATEGORIES_LIST_REQUEST`;
export const GET_SUBCATEGORIES_LIST_SUCCESS = `${prefix} GET_SUBCATEGORIES_LIST_SUCCESS`;
export const GET_SERVICECARDS_LIST_REQUEST = `${prefix} GET_SERVICECARDS_LIST_REQUEST`;
export const GET_SERVICECARDS_LIST_SUCCESS = `${prefix} GET_SERVICECARDS_LIST_SUCCESS`;
export const GET_SERVICECARD_FILE_REQUEST = `${prefix} GET_SERVICECARD_FILE_REQUEST`;
export const GET_SERVICECARD_FILE_SUCCESS = `${prefix} GET_SERVICECARD_FILE_SUCCESS`;
export const CHANGE_BASE_FONT_SIZE = `${prefix} CHANGE_BASE_FONT_SIZE`;
export const DEACTIVATE_SERVICE = `${prefix} DEACTIVATE_SERVICE`;

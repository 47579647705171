import React from 'react';

import './styles.scss';
import { number, string } from 'prop-types';
import FontChange from '../FontChange';
import { Link } from 'react-router-dom';

export const Navbar = ({
    bipUrl,
    logoUrl,
    selectedBaseFontSize,
    serviceName,
}) => {
    return (
        <div className="navbar">
            <Link className="navbar__service-logo" to="/">
                {logoUrl && <img alt={'logo ' + serviceName} src={logoUrl} />}
                {serviceName}
            </Link>
            {bipUrl && (
                <a className="navbar__bip-link" href={bipUrl}>
                    <span className="navbar__bip-link__text">BIP</span>
                </a>
            )}

            <FontChange selectedBaseFontSize={selectedBaseFontSize} />
        </div>
    );
};

Navbar.propTypes = {
    bipUrl: string.isRequired,
    logoUrl: string.isRequired,
    serviceName: string.isRequired,
    selectedBaseFontSize: number.isRequired,
};

import React from 'react';

import { Container } from '../Container';
import { Logo } from './components/Logo';
import { Navbar } from './components/Navbar';

import './styles.scss';
import { number, string } from 'prop-types';

export class AppHeader extends React.Component {
    render() {
        return (
            <>
                <Container>
                    <div className="app-header no-print">
                        <Logo
                            logoSrc={this.props.logoUrl}
                            clientShortName={this.props.clientShortName}
                            homePageUrl={this.props.homePageUrl}
                        />
                        <Navbar
                            bipUrl={this.props.bipUrl}
                            logoUrl={this.props.serviceLogoUrl}
                            selectedBaseFontSize={
                                this.props.selectedBaseFontSize
                            }
                            serviceName={this.props.serviceName}
                        />
                    </div>
                </Container>
            </>
        );
    }
}

AppHeader.propTypes = {
    serviceLogoUrl: string.isRequired,
    clientShortName: string.isRequired,
    bipUrl: string.isRequired,
    logoUrl: string.isRequired,
    homePageUrl: string.isRequired,
    serviceName: string.isRequired,
    selectedBaseFontSize: number.isRequired,
};

import { array, bool, func, object, shape } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCategories, getSubCategoriesList, } from '../../features/service/actions';
import { categoriesListSelector } from '../../features/service/selectors';

import { Container } from '../../components/Container';
import { CategoriesList } from './components/CategoriesList';
import { SubCategoriesList } from './components/SubCategoriesList';
import SearchBar from "../../components/SearchBar";

const Categories = ({
    getCategories,
    getSubCategoriesList,
    subCategoriesList,
    categories,
    match: { params },
    loadingSubCategories,
}) => {
    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <>
            <SearchBar />
            <Container>
                <CategoriesList
                    categories={categories}
                    getSubCategoriesList={getSubCategoriesList}
                    categoryID={params.id}
                />
                <SubCategoriesList
                    subCategoriesList={subCategoriesList}
                    categories={categories}
                    categoryID={params.id}
                    loading={loadingSubCategories}
                />
            </Container>
        </>
    );
};

Categories.propTypes = {
    getCategories: func.isRequired,
    getSubCategoriesList: func.isRequired,
    subCategoriesList: array,
    categories: array,
    match: shape({ params: object }),
    loadingSubCategories: bool,
};

export default connect(
    state => ({ ...categoriesListSelector(state) }),
    {
        getCategories,
        getSubCategoriesList,
    }
)(Categories);

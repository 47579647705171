import { array } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { map } from 'lodash';
import { buildServiceCardPath } from "../../../../../../services/path-builders";

export const RelatedServiceCards = ({ relatedServiceCards }) => {
    return (
        <div className="sidebar-block__redirect">
            {map(relatedServiceCards, ({ title, id }) => {
                return (
                    <Link
                        to={buildServiceCardPath(id)}
                        className="sidebar-block__link"
                        key={id}
                    >
                        {title}
                    </Link>
                );
            })}
        </div>
    );
};

RelatedServiceCards.propTypes = {
    relatedServiceCards: array.isRequired,
};

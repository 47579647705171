import { sortBy } from 'lodash';

export const dashboardSelector = ({ service: { categories, loading } }) => ({
    categories,
    loading,
});

export const cardsSelector = ({
    service: { cardData, loadingCard, loadingFile, cardNotFound },
}) => ({
    categories: cardData.categories,
    content:
        cardData.content ||
        (cardData.sections ? sortBy(cardData.sections, 'no') : []),
    contact: cardData.responsibleEntity ? cardData.responsibleEntity : {},
    type: cardData.type ? cardData.type : '',
    kind: cardData.kind ? cardData.kind : '',
    title: cardData.title,
    description: cardData.description,
    url: cardData.externalServiceUri || cardData.eFormUri,
    loadingCard,
    attachments: cardData.attachments ? cardData.attachments : [],
    relatedServiceCards: cardData.relatedServiceCards
        ? cardData.relatedServiceCards
        : [],
    legal: cardData.legalFoundations ? cardData.legalFoundations : [],
    modified: cardData.modified,
    sourceAndLicense: cardData.source,
    loadingFile,
    cardNotFound,
});

export const categoriesListSelector = ({
    service: { subCategoriesList, categories, loadingSubCategories },
}) => ({
    subCategoriesList,
    categories,
    loadingSubCategories,
});

export const subCategoriesListSelector = ({
    service: {
        serviceCards,
        serviceCardsListPagination,
        subCategoriesList,
        serviceCardsList,
        loadingServiceCards,
    },
}) => ({
    subCategoriesList,
    serviceCardsList,
    loadingServiceCards,
    serviceCards,
    serviceCardsListPagination,
});

import React from 'react';

import './styles.scss';
import { number, string } from 'prop-types';

export class AppFooter extends React.Component {
    render() {
        return (
            <div className="app-footer no-print">
                <div className="app-footer__copyrights">
                    {this.props.copyrightYear} © {this.props.clientFullName}.
                    Wszelkie prawa zastrzeżone. Serwis dostarczany przez{' '}
                    <a
                        className="app-footer__link"
                        href="https://ammsystems.pl"
                    >
                        AMM Systems Sp. z o.o.
                    </a>
                </div>
            </div>
        );
    }
}

AppFooter.propTypes = {
    logoSrc: string.isRequired,
    clientShortName: string.isRequired,
    clientFullName: string.isRequired,
    clientHomePageUrl: string.isRequired,
    copyrightYear: number.isRequired,
};

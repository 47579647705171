import { array, func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Sidebar } from '../../../../components/Sidebar';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';

import { BREADCRUMBS } from '../../../../constants/breadcrumbs';

import { map } from 'lodash';
import { breadcrumbsMapper } from '../../../../services/mappers';
import {
    buildCategoryPath,
    buildSubcategoryPath,
} from '../../../../services/path-builders';
import { connect } from 'react-redux';
import { categoriesListSelector } from '../../../../features/service/selectors';

export const SubCategoriesList = ({
    getServiceCardsList,
    subCategoriesList,
    categoryID,
    subCategoryID,
    categories,
}) => {
    useEffect(() => {
        getServiceCardsList(subCategoryID);
    }, [getServiceCardsList, subCategoryID]);

    const links = [...BREADCRUMBS.CATEGORIES.links];

    const category = breadcrumbsMapper(categories, categoryID);
    if (category) {
        links.push({
            link: buildCategoryPath(categoryID),
            text: category.name,
        });
    }

    const current = breadcrumbsMapper(subCategoriesList, subCategoryID);

    return (
        <React.Fragment>
            <Breadcrumbs links={links} current={current ? current.name : ''} />
            <div className="col-12 col-lg-4 col-xl-3">
                <Sidebar title="Podkategorie" icon={<Grid />}>
                    {map(subCategoriesList, ({ name, id }) => {
                        return (
                            <Link
                                key={id}
                                to={buildSubcategoryPath(categoryID, id)}
                                className={classNames('sidebar-block__item', {
                                    'sidebar-block__item--active':
                                        subCategoryID === id,
                                })}
                            >
                                {name}
                            </Link>
                        );
                    })}
                    <div className="sidebar-block__redirect">
                        <Link
                            to={buildCategoryPath(categoryID)}
                            className="sidebar-block__link sidebar-block__link--color"
                        >
                            &#60; <span>Wróć do wszystkich podkategorii</span>
                        </Link>
                    </div>
                </Sidebar>
            </div>
        </React.Fragment>
    );
};

SubCategoriesList.propTypes = {
    getServiceCardsList: func.isRequired,
    categoryID: string.isRequired,
    subCategoriesList: array,
    categories: array,
    subCategoryID: string.isRequired,
};

export default connect(state => ({ ...categoriesListSelector(state) }))(
    SubCategoriesList
);

import { Loader } from "../../components/Loader";
import React from 'react';
import './styles.scss';

const ServiceLoader = () => {
    return (
        <div className='service-loader'>
            <Loader loading={true} className='service-loader__loader' />
        </div>
    );
}

export default ServiceLoader;

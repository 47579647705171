export const isNullOrUndefined = input => {
    return !!(input === undefined || input === null);
};

export const isEmptyObject = object => {
    return (
        isNullOrUndefined(object) ||
        !!(Object.keys(object).length === 0 && object.constructor === Object)
    );
};

export const isEmptyString = string => {
    return isNullOrUndefined(string) || string.length === 0;
};

export const isEmptyArray = array => {
    return isNullOrUndefined(array) || array.length === 0;
};

import _axios from "axios";
import { deactivateService } from "./actions";
import store from "../../store";

const axios = _axios.create();

axios.interceptors.response.use(
    undefined,
    error => {
        if (error && error.response && error.response.status === 403) {
            store.dispatch(deactivateService());
        }
        throw error;
    }
)

export default axios;

import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import {
    getCategoriesCall,
    getClientConfigCall,
    getServiceCardCall,
    getServiceCardFileCall,
    getServiceCardsListCall,
    getSubCategoriesListCall,
    saveSelectedBaseFontSize,
} from './repository';
import { constants } from './index';
import { save } from '../../services/file-saver';
import { buildSubcategoryPath } from '../../services/path-builders';

export const getClientConfigRequest = createAction(constants.GET_CLIENT_CONFIG);
export const getServiceCardRequest = createAction(
    constants.GET_SERVICE_CARD_REQUEST
);
export const getServiceCardRequestSuccess = createAction(
    constants.GET_SERVICE_CARD_SUCCESS
);
export const getServiceCardNotFound = createAction(
    constants.GET_SERVICE_CARD_NOT_FOUND
);
export const getCategoriesListId = createAction(
    constants.GET_CATEGORIES_LIST_ID
);
export const getCategoriesListFulfilled = createAction(
    constants.GET_CATEGORIES_LIST_FULFILLED
);
export const getSubCategoriesListRequest = createAction(
    constants.GET_SUBCATEGORIES_LIST_REQUEST
);
export const getSubCategoriesListSuccess = createAction(
    constants.GET_SUBCATEGORIES_LIST_SUCCESS
);
export const getCategoriesRequest = createAction(
    constants.GET_CATEGORIES_REQUEST
);
export const getCategoriesSuccess = createAction(
    constants.GET_CATEGORIES_SUCCESS
);
export const getServiceCardsListRequest = createAction(
    constants.GET_SERVICECARDS_LIST_REQUEST
);
export const getServiceCardsListSuccess = createAction(
    constants.GET_SERVICECARDS_LIST_SUCCESS
);
export const getServiceCardFileRequest = createAction(
    constants.GET_SERVICECARD_FILE_REQUEST
);
export const getServiceCardFileSuccess = createAction(
    constants.GET_SERVICECARD_FILE_SUCCESS
);
export const changeBaseFontSize = createAction(constants.CHANGE_BASE_FONT_SIZE);
export const deactivateService = createAction(constants.DEACTIVATE_SERVICE);

export const getServiceCard = id => {
    return dispatch => {
        dispatch(getServiceCardRequest());
        return getServiceCardCall(id)
            .then(data => {
                dispatch(getServiceCardRequestSuccess(data));
            })
            .catch(error => {
                dispatch(getServiceCardNotFound());
                console.log(error);
            });
    };
};

export const getCategories = () => {
    return dispatch => {
        dispatch(getCategoriesRequest());
        return getCategoriesCall()
            .then(data => {
                dispatch(getCategoriesSuccess(data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getServiceCardsList = (id, page = 1) => {
    return dispatch => {
        dispatch(getServiceCardsListRequest());
        return getServiceCardsListCall(id, page)
            .then(({ data, headers }) => {
                dispatch(getServiceCardsListSuccess({ data, headers }));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getSubCategoriesList = id => {
    return dispatch => {
        dispatch(getSubCategoriesListRequest());
        return getSubCategoriesListCall(id)
            .then(data => {
                dispatch(getSubCategoriesListSuccess(data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const getServiceCardFile = id => {
    return dispatch => {
        dispatch(getServiceCardFileRequest());
        return getServiceCardFileCall(id)
            .then(({ data, headers }) => {
                save(data, headers);
                dispatch(getServiceCardFileSuccess());
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const changeSubCategoriesRoute = (subCategoryID, categoryID) => {
    return dispatch => {
        dispatch(push(buildSubcategoryPath(categoryID, subCategoryID)));
    };
};

export const getClientConfig = hostaname => {
    return dispatch => {
        return getClientConfigCall(hostaname)
            .then(data => {
                dispatch(getClientConfigRequest(data));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const changeServiceBaseFontSize = no => {
    return dispatch => {
        saveSelectedBaseFontSize(no);
        dispatch(changeBaseFontSize(no));
    };
};

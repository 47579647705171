import React from 'react';
import loader from './loader.svg';
import { func, string } from 'prop-types';

export class File extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    downloadFile = id => {
        const { getServiceCardFile } = this.props;
        this.setState({
            loading: true,
        });
        getServiceCardFile(id).then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    render() {
        const { id, name } = this.props;
        const { loading } = this.state;
        return (
            <span
                className="sidebar-block__link"
                key={id}
                onClick={() => this.downloadFile(id)}
            >
                {name}
                {loading && (
                    <img
                        src={loader}
                        alt={name}
                        className="sidebar-block__attachment-loader"
                    />
                )}
            </span>
        );
    }
}

File.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    getServiceCardFile: func.isRequired,
};

import { combineEpics, ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { LOCATION_CHANGE } from 'connected-react-router';
import { GET_CATEGORIES_LIST_ID } from './constants';
import { getCategoriesListFulfilled } from './actions';

import { fetchSubCategoriesList } from './repository';

export const getCategoriesListEpic = action$ =>
    action$.pipe(
        ofType(GET_CATEGORIES_LIST_ID),
        switchMap(({ payload }) => {
            return concat(
                ajax.getJSON(fetchSubCategoriesList(payload)).pipe(
                    takeUntil(action$.pipe(ofType(LOCATION_CHANGE))),
                    map(response => getCategoriesListFulfilled(response)),
                    catchError(error => {
                        console.log(error);
                    })
                )
            );
        })
    );

export default combineEpics(getCategoriesListEpic);

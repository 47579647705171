import { filter, join, map } from 'lodash';
import { ServiceCardTypeEnum } from '../enum/service_card_type_enum';
import { ArticleTypeEnum } from '../enum/article_type_enum';
import { ArticleTypeNameEnum } from "../enum/article_type_name_enum";

export const articleTypeMapper = articleType => {
    switch (articleType) {
        case ArticleTypeEnum.WWW_ARTICLE:
            return ArticleTypeNameEnum.WWW_ARTICLE;
        case ArticleTypeEnum.SERVICE_CARD_SERVICE:
            return ArticleTypeNameEnum.SERVICE_CARD_SERVICE;
        case ArticleTypeEnum.SERVICE_CARD_ISSUE_HANDLE:
            return ArticleTypeNameEnum.SERVICE_CARD_ISSUE_HANDLE;
        default:
            return 'Wszystkie';
    }
};

export const serviceCardTypeMapper = type => {
    switch (type) {
        case ServiceCardTypeEnum.ISSUE_HANDLE:
            return ArticleTypeNameEnum.SERVICE_CARD_ISSUE_HANDLE;
        case ServiceCardTypeEnum.SERVICE:
            return ArticleTypeNameEnum.SERVICE_CARD_SERVICE;
        default:
            return 'Karty Usług';
    }
};

export const serviceCardsCategoriesMapper = (data, urlID) =>
    join(
        map(
            filter(
                map(data, ({ id, name }) => ({ id, name })),
                ({ id }) => urlID === id
            ),
            ({ name }) => name
        ),
        ','
    );

export const breadcrumbsMapper = (data, urlID) =>
    filter(
        map(data, ({ id, name }) => ({ id, name })),
        ({ id }) => urlID === id
    )[0];

export const urlIdMapper = (data, urlID) =>
    filter(data, ({ id }) => {
        return urlID === id;
    });

import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import history from '../services/history';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';
import { composeEnhancers } from './utils';
import thunk from 'redux-thunk';

const epicMiddleware = createEpicMiddleware();

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk, epicMiddleware)
);

function configureStore(initialState) {
    const storeInstance = createStore(rootReducer, initialState, enhancer);
    epicMiddleware.run(rootEpic);

    return storeInstance;
}

const store = configureStore();

export default store;

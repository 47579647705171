import React from 'react';
import { array, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { map } from 'lodash';

import './styles.scss';

export const Breadcrumbs = ({ links, current }) => {
    return (
        <div className="col-12">
            <div className="breadcrumbs">
                {links &&
                    map(links, ({ link, text }) => {
                        if (link.match(/^https?:/)) {
                            return (
                                <a
                                    key={link}
                                    href={link}
                                    className="breadcrumbs__link"
                                >
                                    {text}
                                </a>
                            );
                        }

                        return (
                            <Link
                                key={link}
                                to={link}
                                className="breadcrumbs__link"
                            >
                                {text}
                            </Link>
                        );
                    })}
                <p className="breadcrumbs__current">{current}</p>
            </div>
        </div>
    );
};

Breadcrumbs.propTypes = {
    links: array,
    current: string.isRequired,
};

import { ignoreElements, tap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

export const exampleEpic = action$ =>
    action$.pipe(
        tap(() => {}),
        ignoreElements()
    );

export default combineEpics(exampleEpic);

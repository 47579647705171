import { array, func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Grid } from 'react-feather';
import classNames from 'classnames';

import { Sidebar } from '../../../../components/Sidebar';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';

import { BREADCRUMBS } from '../../../../constants/breadcrumbs';

import { map } from 'lodash';
import { breadcrumbsMapper } from '../../../../services/mappers';
import { Link } from 'react-router-dom';
import { buildCategoryPath } from '../../../../services/path-builders';

export const CategoriesList = ({
    categories,
    getSubCategoriesList,
    categoryID,
}) => {
    useEffect(() => {
        getSubCategoriesList(categoryID);
    }, [getSubCategoriesList, categoryID]);

    const { links } = BREADCRUMBS.CATEGORIES;
    const current = breadcrumbsMapper(categories, categoryID);

    return (
        <React.Fragment>
            <Breadcrumbs links={links} current={current ? current.name : ''} />
            <div className="col-12 col-lg-4 col-xl-3">
                <Sidebar title="Kategorie" icon={<Grid />}>
                    {map(categories, ({ name, id }) => {
                        return (
                            <Link
                                to={buildCategoryPath(id)}
                                key={id}
                                className={classNames('sidebar-block__item', {
                                    'sidebar-block__item--active':
                                        categoryID === id,
                                })}
                            >
                                {name}
                            </Link>
                        );
                    })}
                </Sidebar>
            </div>
        </React.Fragment>
    );
};

CategoriesList.propTypes = {
    getSubCategoriesList: func.isRequired,
    categories: array,
    categoryID: string.isRequired,
};

import React from "react";
import SearchBar from "../../components/SearchBar";
import * as icon from "./service-deactivated.jpg";
import './styles.scss';

const ServiceDeactivated = () => {
    return (
        <div className='service-deactivated'>
            <SearchBar/>
            <div className='service-deactivated__info'>
                <img src={ icon } alt=""/>
                <div className='service-deactivated__text'>
                    Przepraszamy, w tej chwili usługa jest niedostępna.
                </div>
            </div>
        </div>
    );
}

export default ServiceDeactivated;

class SessionIdRepository {
    static REQUIRED_SESSION_ID_LENGTH = 32;
    static STORAGE_KEY = 'searchEngineSessionId';

    static get = () => {
        const stored =
            localStorage.getItem(SessionIdRepository.STORAGE_KEY) || '';
        if (stored.length === SessionIdRepository.REQUIRED_SESSION_ID_LENGTH) {
            return stored;
        }
        const newSessionId = this.generate();

        localStorage.setItem(SessionIdRepository.STORAGE_KEY, newSessionId);

        return newSessionId;
    };

    static generate = () => {
        const generateRandomString = () => {
            return Math.random()
                .toString(36)
                .substring(2, 15);
        };

        let generatedString = '';

        do {
            generatedString += generateRandomString();
        } while (
            generatedString.length <
            SessionIdRepository.REQUIRED_SESSION_ID_LENGTH
        );

        return generatedString.substr(
            0,
            SessionIdRepository.REQUIRED_SESSION_ID_LENGTH
        );
    };
}

export default SessionIdRepository;

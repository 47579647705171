import React from 'react';
import { connect } from 'react-redux';
import { searchDashboardSelector } from '../../features/search/selectors';
import { Container } from "../Container";
import { array, bool, func, number, string } from "prop-types";
import { updateSearchQuery, } from '../../features/search/actions';
import Search, { SearchBoxType } from "../Search";
import './styles.scss';

class SearchBar extends React.Component {
    render() {
        const {
            searchQuery,
            forcedSearchQuery,
            autocompleteList,
            loading,
            noResultsReason,
            onSearchQueryChange,
            onSearchSubmit,
            type,
            updateSearchQuery
        } = this.props;

        return (
            <Container wide={true} className="search-bar-container">
                <Container>
                    <div className="search-bar">
                        <h2 className='search-bar__title'>Załatw sprawę</h2>
                        <Search
                            searchQuery={forcedSearchQuery || searchQuery}
                            loading={loading}
                            autocompleteList={autocompleteList}
                            noResultsReason={noResultsReason}
                            type={type || SearchBoxType.DEFAULT}
                            onSearchSubmit={onSearchSubmit}
                            onSearchQueryChange={onSearchQueryChange}
                            updateSearchQuery={updateSearchQuery}
                        />
                    </div>
                </Container>
            </Container>
        );
    }
}

SearchBar.propTypes = {
    searchQuery: string,
    forcedSearchQuery: string,
    autocompleteList: array,
    loading: bool,
    noResultsReason: string,
    onSearchQueryChange: func,
    onSearchSubmit: func,
    type: number,
    updateSearchQuery: func
}

export default connect(
    state => ({ ...searchDashboardSelector(state) }),
    {
        updateSearchQuery
    }
)(SearchBar);

import { bool, string, func } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { trimStart, trim } from 'lodash';

import clear from './x.svg';
import './styles.scss';

export const Input = ({
    classNameLabel,
    classNameInput,
    big,
    placeholder,
    name,
    value,
    label,
    onChange,
    onFocus,
    onBlur,
    onKeyPress,
    clearInput,
}) => {
    return (
        <div className="input-field">
            <label
                className={classNames('label', classNameLabel, {
                    label__big: big,
                })}
                htmlFor={name}
            >
                {label}
            </label>
            <input
                className={classNames('input', classNameInput, {
                    input__big: big,
                })}
                placeholder={placeholder}
                name={name}
                value={trimStart(value)}
                onChange={onChange}
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                autoComplete="off"
            />
            {trim(value) && (
                <img
                    className={classNames('input-clear', {
                        'input-clear__big': big,
                    })}
                    src={clear}
                    onClick={clearInput}
                    alt="Clear Input"
                />
            )}
        </div>
    );
};

Input.propTypes = {
    clearInput: func,
    classNameInput: string,
    classNameLabel: string,
    big: bool.isRequired,
    placeholder: string.isRequired,
    name: string.isRequired,
    value: string.isRequired,
    label: string,
    onChange: func,
    onFocus: func,
    onKeyPress: func,
    onBlur: func,
};

Input.defaultProps = {
    big: false,
};

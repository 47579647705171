import axios from './axios';
import { blob_api_url, ku_api_url } from '../../constants/api';
import { ClientConfig } from '../../clientConfigApplicator';

export const fetchSubCategoriesList = id =>
    `${ku_api_url}categories/${encodeURIComponent(id)}/subcategories`;

export const getServiceCardCall = async id => {
    const request = {
        method: 'GET',
        url: `${ku_api_url}service-cards/${encodeURIComponent(id)}`,
    };
    const { data } = await axios(request);

    return data;
};

export const getSubCategoriesListCall = async id => {
    const request = {
        method: 'GET',
        url: `${ku_api_url}categories/${id}/subcategories`,
    };
    const { data } = await axios(request);

    return data;
};

export const getServiceCardsListCall = async (id, page = 1) => {
    const request = {
        method: 'GET',
        url: `${ku_api_url}subcategories/${id}/service-cards?p.size=20&p.page=${page}`,
    };
    const { data, headers } = await axios(request);

    return { data, headers };
};

export const getCategoriesCall = async () => {
    const request = {
        method: 'GET',
        url: `${ku_api_url}${ClientConfig.clientId}/categories`,
    };
    const { data } = await axios(request);

    return data;
};

export const getServiceCardFileCall = async id => {
    const request = {
        method: 'GET',
        url: `${blob_api_url}files/${id}`,
        responseType: 'blob',
    };

    return await axios(request);
};

export const getClientConfigCall = async hostname => {
    const request = {
        method: 'GET',
        url: `${ku_api_url}configs/${encodeURI(hostname)}`,
    };

    const { data } = await axios(request);

    return data;
};

export const getSelectedBaseFontSize = () => {
    return localStorage.getItem('selectedBaseFontSize') || 1;
};

export const saveSelectedBaseFontSize = selectedBaseFontSize => {
    return Number(
        localStorage.setItem('selectedBaseFontSize', selectedBaseFontSize)
    );
};

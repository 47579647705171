import { array, bool, func, number, string } from 'prop-types';
import { Link, Redirect, withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { includes, lt } from 'lodash';

import { clearSearchQuery } from '../../features/search/actions';
import { redirectCall } from '../../features/application/actions';

import { Autocomplete } from './Autocomplete';
import { Button } from '../Button';
import { Input } from '../Input';

import { CSSTransition } from 'react-transition-group';

import { ReactComponent as SearchIcon } from './search.svg';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
        };
    }

    onSubmitClicked = () => {
        this.setState({ redirect: true });
        if (this.props.onSearchSubmit) {
            this.props.onSearchSubmit();
        }
    };

    onInputChange = event => {
        const { value } = event.target;

        const { updateSearchQuery, onSearchQueryChange } = this.props;

        updateSearchQuery(value);

        if (onSearchQueryChange) {
            onSearchQueryChange(value);
        }
    };

    handleKeyPress = event => {
        const { searchQuery } = this.props;
        if (
            (event.key === 'Enter' || event.keyCode === 13) &&
            lt(0, searchQuery.length)
        ) {
            event.preventDefault();
            this.setState({ redirect: true });
        }
    };

    render() {
        const {
            searchQuery,
            clearSearchQuery,
            autocompleteList,
            loading,
            noResultsReason,
            redirectCall,
            type,
        } = this.props;

        const searchBoxType = type || SearchBoxType.DASHBOARD;

        if (
            searchBoxType !== SearchBoxType.SEARCH_RESULTS &&
            this.state.redirect
        ) {
            return (
                <Redirect push to={'/search-results?query=' + searchQuery} />
            );
        }

        return (
            <>
                {searchBoxType === SearchBoxType.DASHBOARD && (
                    <>
                        <Input
                            onChange={this.onInputChange}
                            onKeyPress={this.handleKeyPress}
                            placeholder="Jaką sprawę chcesz załatwić?"
                            big={true}
                            name="search"
                            value={searchQuery}
                            label={'Skorzystaj z wyszukiwarki'}
                            classNameInput="dashboard-search__input"
                            classNameLabel="dashboard-search__label"
                            clearInput={() => clearSearchQuery()}
                        />

                        <Button
                            primary={true}
                            big={true}
                            className="dashboard-search__button"
                            onClick={this.onSubmitClicked}
                        >
                            Szukaj
                        </Button>
                    </>
                )}

                {includes(
                    [SearchBoxType.SEARCH_RESULTS, SearchBoxType.DEFAULT],
                    searchBoxType
                ) && (
                    <>
                        <input
                            onChange={this.onInputChange}
                            onKeyPress={this.handleKeyPress}
                            placeholder="Jaką sprawę chcesz załatwić?"
                            name="search"
                            value={searchQuery}
                            className="dashboard-search__input"
                        />

                        <Link
                            className="dashboard-search__button button button__primary"
                            to={`/search-results?query=${searchQuery}`}
                            onClick={this.onSubmitClicked}
                        >
                            <SearchIcon />
                        </Link>
                    </>
                )}

                {includes(
                    [SearchBoxType.DASHBOARD, SearchBoxType.DEFAULT],
                    searchBoxType
                ) && (
                    <CSSTransition
                        in={searchQuery.length > 2}
                        timeout={375}
                        classNames="autocomplete"
                        unmountOnExit
                    >
                        <Autocomplete
                            autocompleteList={autocompleteList}
                            loading={loading}
                            searchResultsRedirect={this.onSubmitClicked}
                            noResultsReason={noResultsReason}
                            redirectCall={redirectCall}
                        />
                    </CSSTransition>
                )}
            </>
        );
    }
}

Search.propTypes = {
    updateSearchQuery: func.isRequired,
    clearSearchQuery: func,
    searchQuery: string,
    loading: bool,
    noResultsReason: string,
    autocompleteList: array,
    redirectCall: func,
    onSearchQueryChange: func,
    type: number,
    onSearchSubmit: func,
};

export default withRouter(
    connect(
        state => ({}),
        {
            clearSearchQuery,
            redirectCall,
        }
    )(Search)
);

export const SearchBoxType = Object.freeze({
    DEFAULT: 1,
    DASHBOARD: 2,
    SEARCH_RESULTS: 3,
});

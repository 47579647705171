import axios from 'axios';
import { es_api_url } from '../../constants/api';
import { MAX_ARTICLES_PER_PAGE } from '../../constants/search';
import SessionIdRepository from './session_id_repository';
import { ClientConfig } from '../../clientConfigApplicator';

const sessionId = SessionIdRepository.get();

export const searchQuery = term =>
    `${es_api_url}${ClientConfig.clientId}/articles?query=${encodeURIComponent(
        term
    )}&sessionId=${sessionId}`;

export const searchResultsCall = async (
    query,
    categories,
    keywords,
    type = '',
    page = 1,
) => {
    const request = {
        method: 'GET',
        url: `${es_api_url}${ClientConfig.clientId}/articles`,
    };
    const params = new URLSearchParams();
    params.append('sessionId', sessionId);
    params.append('query', query);
    params.append('types', type);
    params.append('p.page', page);
    params.append('p.size', MAX_ARTICLES_PER_PAGE);
    params.append('categories', categories.join('|'));
    params.append('keywords', keywords.join('|'));

    request.params = params;
    const { data } = await axios(request);

    return data;
};

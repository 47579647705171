import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { string } from 'prop-types';
import SVG from 'react-inlinesvg';

import './styles.scss';
import { buildCategoryPath } from '../../services/path-builders';

export const CategoryTile = ({ image, description, title, id }) => {
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Redirect to={buildCategoryPath(id)} push />;
    }

    return (
        <div className="col-12 col-sm-6 col-lg-3 p-2">
            <div className="category-tile">
                <div
                    onClick={() => {
                        setRedirect(true);
                    }}
                >
                    <div className="category-tile__header">
                        <span className="category-tile__image">
                            <SVG src={image} />
                        </span>
                        <span className="category-tile__link">
                            <p>{title}</p>
                        </span>
                    </div>
                    <div className="category-tile__content">{description}</div>
                </div>
            </div>
        </div>
    );
};

CategoryTile.propTypes = {
    id: string.isRequired,
    image: string.isRequired,
    description: string.isRequired,
    title: string.isRequired,
};

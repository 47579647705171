import { array, func, string } from 'prop-types';
import React, { useState } from 'react';

import { Checkbox } from '../Checkbox';
import { isMobile } from '../../services/mobile';

import { map } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import arrowRight from './arrow-right.svg';
import './styles.scss';
import SVG from 'react-inlinesvg';


export const Filters = ({ title, filtersImage, data, onChecked }) => {
    const [filtersVisibility, toggleFilters] = useState(!isMobile());

    const toggle = () => toggleFilters(!filtersVisibility);

    return (
        <div className="filters-block">
            <div className="filters-block__title">
                <SVG
                    src={filtersImage}
                    className="filters-block__image"
                />
                <span>{title}</span>
                <SVG
                    src={arrowRight}
                    className={classNames('filters-block__toggle', {
                        'filters-block__toggle--visible': filtersVisibility,
                    })}
                    onClick={toggle}
                />
            </div>
            <CSSTransition
                in={filtersVisibility}
                timeout={375}
                classNames="filters-block__items"
                unmountOnExit
            >
                <div className="filters-block__items">
                    {map(data, ({ name, isSelected, active }) => {
                        return (
                            <Checkbox
                                key={name}
                                label={name}
                                name={name}
                                onChecked={() =>
                                    onChecked({
                                        isSelected: !isSelected,
                                        name,
                                    })
                                }
                                checked={isSelected}
                                disabled={!active}
                                className="search-filters__checkbox"
                            />
                        );
                    })}
                </div>
            </CSSTransition>
        </div>
    );
};

Filters.propTypes = {
    data: array.isRequired,
    onChecked: func.isRequired,
    title: string.isRequired,
    filtersImage: string.isRequired,
};

import { ArticleTypeEnum } from '../enum/article_type_enum';
import { ArticleTypeNameEnum } from "../enum/article_type_name_enum";

export const tabGroupData = () => [
    {
        name: 'Wszystko',
        value: '',
        id: 1,
        isSelected: true
    },
    {
        name: ArticleTypeNameEnum.SERVICE_CARD_ISSUE_HANDLE,
        value: ArticleTypeEnum.SERVICE_CARD_ISSUE_HANDLE,
        id: 2
    },
    {
        name: ArticleTypeNameEnum.SERVICE_CARD_SERVICE,
        value: ArticleTypeEnum.SERVICE_CARD_SERVICE,
        id: 3
    },
    {
        name: ArticleTypeNameEnum.WWW_ARTICLE,
        value: ArticleTypeEnum.WWW_ARTICLE,
        id: 4,
    },
];

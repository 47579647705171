import {array} from 'prop-types';
import React from 'react';

import {CategoryTile} from '../../../../components/CategoryTile';

import {map} from 'lodash';

export const ServiceCardCategories = ({ categories }) => {
    return (
        <React.Fragment>
            {map(categories, ({ id, name, iconUri, description }) => {
                return (
                    <CategoryTile
                        key={id}
                        id={id}
                        image={iconUri}
                        title={name}
                        description={description}
                    />
                );
            })}
        </React.Fragment>
    );
};

ServiceCardCategories.propTypes = {
    categories: array.isRequired,
};

import React from 'react';
import { isEmpty } from 'lodash';
import { Button } from '../../../../components/Button';
import { Count } from '../../../../components/Count';
import { Filters } from '../../../../components/Filters';
import { TabGroup } from '../../../../components/TabGroup';
import key from './key.svg';
import grid from './grid.svg';
import './styles.scss';
import { array, bool, func, number, object } from 'prop-types';
import { isEmptyArray } from '../../../../core/utils';

export const SearchFilters = ({
    keywords,
    categories,
    selectSingleCategory,
    selectSingleKeyword,
    selectSingleType,
    clearFilters,
    count,
    types,
    loadingResults,
    isMobile,
    urlParamsManipulator,
}) => {

    const filtersDisabled = () => {
        return (
            isEmptyArray(urlParamsManipulator.getCategories()) &&
            isEmptyArray(urlParamsManipulator.getKeywords()) &&
            !urlParamsManipulator.getType() &&
            count === 0
        );
    }

    return (
        <React.Fragment>
            <Count
                count={count}
                loading={loadingResults}
                title="Znalezione wyniki:"
                className="search-count"
            />
            {
                !filtersDisabled() &&
                <TabGroup
                    items={types}
                    onChange={selectSingleType}
                    className="search-types"
                    isMobile={isMobile}
                    title='Pokaż:'
                    label='Wybierz typ wyników:'
                />
            }

            <div className="col-12 col-lg-4 col-xl-3">
                <div className="search-filters">
                    {!isEmpty(categories) && (
                        <Filters
                            title="Kategorie"
                            filtersImage={grid}
                            data={categories}
                            onChecked={selectSingleCategory}
                        />
                    )}
                    {!isEmpty(keywords) && (
                        <Filters
                            title="Słowa kluczowe"
                            filtersImage={key}
                            data={keywords}
                            onChecked={selectSingleKeyword}
                        />
                    )}
                    {
                        !filtersDisabled() &&
                        <Button
                            inverted={ true }
                            className="search-filters__button"
                            onClick={ clearFilters }
                            disabled={ isButtonDisabled(urlParamsManipulator) }
                        >
                            WYCZYŚĆ FILTRY
                        </Button>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

const isButtonDisabled = urlParamsManipulator => {
    return (
        isEmptyArray(urlParamsManipulator.getCategories()) &&
        isEmptyArray(urlParamsManipulator.getKeywords()) &&
        !urlParamsManipulator.getType()
    );
};

SearchFilters.propTypes = {
    keywords: array,
    categories: array,
    types: array,
    selectSingleCategory: func,
    selectSingleKeyword: func,
    selectSingleType: func,
    selectSingleTypeMobile: func,
    clearFilters: func,
    count: number.isRequired,
    loadingResults: bool,
    isMobile: bool.isRequired,
    urlParamsManipulator: object.isRequired,
};

export default SearchFilters;

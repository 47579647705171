import { array } from 'prop-types';
import React from 'react';
import { map, isEmpty } from 'lodash';

export const Legal = ({ legal }) => {
    return (
        <>
            {!isEmpty(legal) && (
                <div className="content__legal">
                    <span className="content__legal-title">
                        Podstawa prawna
                    </span>
                    <ul className="content__legal-list">
                        {map(legal, (note, index) => (
                            <li className="content__legal-item" key={index}>
                                &#167; <span>{note}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

Legal.propTypes = {
    legal: array,
};

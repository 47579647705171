import { combineEpics, ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
    debounceTime,
    map,
    switchMap,
    filter,
    takeUntil,
    catchError,
} from 'rxjs/operators';
import { trim } from 'lodash';

import { LOCATION_CHANGE } from 'connected-react-router';
import { UPDATE_SEARCH_QUERY } from './constants';
import { autocompleteListFulfilled } from './actions';

import { searchQuery } from './repository';

export const searchEpic = action$ =>
    action$.pipe(
        ofType(UPDATE_SEARCH_QUERY),
        debounceTime(500),
        filter(({ payload }) => trim(payload).length > 2),
        switchMap(({ payload }) => {
            return concat(
                ajax.getJSON(searchQuery(payload)).pipe(
                    takeUntil(action$.pipe(ofType(LOCATION_CHANGE))),
                    map(response => autocompleteListFulfilled(response)),
                    catchError(error => {
                        console.log(error);
                    })
                )
            );
        })
    );

export default combineEpics(searchEpic);

import { array, bool, func, object, string } from 'prop-types';
import React from 'react';

import { Loader } from '../../../../components/Loader';

import { map } from 'lodash';
import { serviceCardsCategoriesMapper, urlIdMapper, } from '../../../../services/mappers';

import './styles.scss';
import { ServiceCardsListItem } from './ServiceCardsListItem';
import { Pagination } from "../../../../components/Pagination";
import { buildServiceCardPath } from "../../../../services/path-builders";

export const ServiceCardsList = ({
    serviceCardsList = [],
    serviceCardsListPagination,
    categoryID,
    serviceCards,
    subCategoryID,
    subCategoriesList,
    loadingServiceCards,
    getServiceCardsList
}) => {
    const serviceCardsDescription = urlIdMapper(
        subCategoriesList,
        subCategoryID
    );
    const categories = serviceCardsCategoriesMapper(serviceCards, categoryID);

    return (
        <div className="col-12 col-lg-8 col-xl-9">
            <Loader className="servicecards-list" loading={loadingServiceCards}>
                {map(serviceCardsDescription, ({ name, id }) => {
                    return (
                        <h1 key={id} className="servicecards-list__heading">
                            {name}
                        </h1>
                    );
                })}
                <div className="servicecards-list__count">
                    Liczba kart usług: {serviceCardsListPagination.total}
                </div>
                {map(serviceCardsList, ({ title, id, description, type }) => {
                    return (
                        <ServiceCardsListItem
                            key={id}
                            title={title}
                            serviceCardType={type}
                            description={description}
                            categories={categories}
                            redirectUrl={buildServiceCardPath(id, subCategoryID)}
                        />
                    );
                })}


                <Pagination
                    page={serviceCardsListPagination.page}
                    pages={serviceCardsListPagination.pages}
                    setPage={(page) => {
                        getServiceCardsList(subCategoryID, Number(page + 1))
                    }}
                    hidden={serviceCardsListPagination.pages <= 1}
                />
            </Loader>
        </div>
    );
};

ServiceCardsList.propTypes = {
    serviceCardsList: array,
    serviceCardsListPagination: object,
    getServiceCardsList: func,
    categoryID: string.isRequired,
    serviceCards: array,
    subCategoryID: string.isRequired,
    subCategoriesList: array,
    loadingServiceCards: bool,
};

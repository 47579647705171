import { array, bool, func, string } from 'prop-types';
import React from 'react';

import { MESSAGES } from '../../../constants/messages';

import { isEmpty, map } from 'lodash';

import './styles.scss';
import { SearchItem } from "../../SearchItem";
import { Spinner } from "../../Spinner";
import { NoContent } from "../../NoContent";

export const Autocomplete = ({
    searchResultsRedirect,
    autocompleteList,
    loading,
    noResultsReason,
    redirectCall,
}) => {
    return (
        <div className="autocomplete">
            {loading ? (
                <Spinner/>
            ) : (
                <React.Fragment>
                    <div className="autocomplete__content">
                        {isEmpty(autocompleteList) ? (
                            <NoContent
                                text={
                                    noResultsReason === 'QUERY'
                                        ? MESSAGES.QUERY_NOT_FOUND
                                        : MESSAGES.SEARCH_RESULTS_NOT_FOUND
                                }
                            />
                        ) : (
                            <React.Fragment>
                                {map(
                                    autocompleteList,
                                    ({
                                        title,
                                        description,
                                        id,
                                        url,
                                        type,
                                        categories,
                                        originalUrl,
                                    }) => {
                                        return (
                                            <SearchItem
                                                autocomplete={true}
                                                key={id}
                                                id={id}
                                                title={title}
                                                category={type}
                                                categories={categories}
                                                description={description}
                                                url={url}
                                                redirectCall={redirectCall}
                                                searchResults={true}
                                                dropdown={true}
                                                originalUrl={originalUrl}
                                            />
                                        );
                                    }
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <div className="autocomplete__cta">
                        <span onClick={searchResultsRedirect}>
                            Pokaż więcej
                        </span>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

Autocomplete.propTypes = {
    searchResultsRedirect: func.isRequired,
    noResultsReason: string,
    autocompleteList: array,
    loading: bool,
    redirectCall: func.isRequired,
};

import { array, bool, func, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { truncate } from 'lodash';
import { articleTypeMapper } from '../../services/mappers';

import './styles.scss';
import { ArticleTypeEnum } from '../../enum/article_type_enum';

export const SearchItem = ({
    title,
    articleType,
    description,
    url,
    originalUrl,
    categories,
    redirectCall,
    autocomplete = false,
    dropdown = false,
}) => {
    return (
        <a
            href={url + '&redirect=1'}
            className={`search-item ${dropdown ? 'search-item--dropdown' : ''}`}
        >
            <span className="search-item__title">{title}</span>

            {!autocomplete && (
                <div className="search-item__subcategory">
                    <span className="search-item__article-type">
                        {articleTypeMapper(articleType)}
                    </span>

                    {articleType === ArticleTypeEnum.WWW_ARTICLE
                        ? formatUrl(originalUrl)
                        : ''}
                    <span>
                        {categories.length > 0
                            ? ' - ' + categories.join(', ')
                            : ''}
                    </span>
                </div>
            )}
            <div className="search-item__description">
                {truncate(description, { length: 278 })}
            </div>
        </a>
    );
};

SearchItem.propTypes = {
    title: string.isRequired,
    articleType: string,
    description: string.isRequired,
    url: string,
    originalUrl: string,
    categories: oneOfType([string, array]),
    redirectCall: func,
    id: string.isRequired,
    eFormUri: object,
    attachmentUrls: array,
    autocomplete: bool,
    dropdown: bool,
};

const formatUrl = url => {
    const maxCharsDisplayed = 53;

    url = url.replace(/.*:\/\//, '');

    if (url.length <= maxCharsDisplayed) {
        return url;
    }

    return url.substring(0, maxCharsDisplayed - 3) + '...';
};

import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';

import { Container } from '../../components/Container';
import { Button } from '../../components/Button';
import { ReactComponent as Img404 } from './404_image.svg';
import './style.scss';

const Page404 = props => {
    return (
        <Container>
            <div className="page404__content">
                <Img404 />
                <h1 className="page404__title">Nie znaleźliśmy strony…</h1>
                <p className="page404__desc">
                    Przepraszamy, podana strona została usunięta lub czasowo
                    ukryta. <br />
                    Zajrzyj za jakiś czas.
                </p>
                <div className="page404__btns-wrapper">
                    {props.history.length > 1 && (
                        <Button
                            onClick={() => props.history.goBack()}
                            inverted={true}
                            className="page404__btn-back"
                        >
                            Wróć na poprzednią stronę
                        </Button>
                    )}
                    <Link to="/" className="page404__btn-main-page">
                        Przejdź do strony głównej
                    </Link>
                </div>
            </div>
        </Container>
    );
};

Page404.propTypes = {
    history: object,
};

export default Page404;

import { combineActions, handleActions } from 'redux-actions';
import { getSelectedBaseFontSize } from './repository';
import {
    CHANGE_BASE_FONT_SIZE,
    DEACTIVATE_SERVICE,
    GET_CATEGORIES_LIST_ID,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CLIENT_CONFIG,
    GET_SERVICE_CARD_NOT_FOUND,
    GET_SERVICE_CARD_REQUEST,
    GET_SERVICE_CARD_SUCCESS,
    GET_SERVICECARD_FILE_REQUEST,
    GET_SERVICECARD_FILE_SUCCESS,
    GET_SERVICECARDS_LIST_REQUEST,
    GET_SERVICECARDS_LIST_SUCCESS,
    GET_SUBCATEGORIES_LIST_REQUEST,
    GET_SUBCATEGORIES_LIST_SUCCESS,
} from './constants';

import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
    cardData: [],
    subCategoriesList: [],
    categories: [],
    serviceCardsList: [],
    serviceCardsListPagination: {
        page: 1,
        size: 20,
        total: 0,
        pages: 1,
    },
    loadingCard: true,
    loadingCategories: true,
    clientConfig: undefined,
    selectedBaseFontSize: getSelectedBaseFontSize && getSelectedBaseFontSize(),
};

const serviceReducer = handleActions(
    {
        [DEACTIVATE_SERVICE]: state => {
            return {
                ...state,
                deactivated: true,
            };
        },
        [CHANGE_BASE_FONT_SIZE]: (state, { payload }) => {
            return {
                ...state,
                selectedBaseFontSize: payload,
            };
        },

        [GET_CLIENT_CONFIG]: (state, { payload }) => {
            return {
                ...state,
                clientConfig: payload,
            };
        },
        [GET_SERVICE_CARD_REQUEST]: (state, { payload }) => {
            return {
                ...state,
                id: payload,
                cardNotFound: false,
            };
        },
        [GET_SERVICE_CARD_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                cardData: payload,
                loadingCard: false,
                cardNotFound: false,
            };
        },
        [GET_SERVICE_CARD_NOT_FOUND]: (state, { payload }) => {
            console.log('NNNNN');
            return {
                ...state,
                loadingCard: false,
                cardNotFound: true,
            };
        },
        [GET_CATEGORIES_LIST_ID]: (state, { payload }) => {
            return {
                ...state,
                id: payload,
            };
        },
        [combineActions(GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS)]: (
            state,
            { type, payload }
        ) => {
            return {
                ...state,
                categories: type === GET_CATEGORIES_REQUEST ? [] : payload,
                loading: type === GET_CATEGORIES_REQUEST ? true : null,
            };
        },
        [combineActions(
            GET_SUBCATEGORIES_LIST_REQUEST,
            GET_SUBCATEGORIES_LIST_SUCCESS
        )]: (state, { type, payload }) => {
            return {
                ...state,
                subCategoriesList: payload,
                loadingSubCategories:
                    type === GET_SUBCATEGORIES_LIST_REQUEST ? true : null,
            };
        },
        [combineActions(GET_SERVICECARDS_LIST_REQUEST)]: state => {
            return {
                ...state,
                loadingServiceCards: true,
            };
        },
        [combineActions(GET_SERVICECARDS_LIST_SUCCESS)]: (
            state,
            { type, payload }
        ) => {
            const { data, headers } = payload;

            const extractPagination = headers => {
                if (!headers) {
                    return null;
                }
                const paginationHeaderValue = headers['pagination'] || '';
                const result = paginationHeaderValue.match(
                    /page=(?<page>\d+),size=(?<size>\d+),total=(?<total>\d+)/
                );

                if (!result.groups) {
                    return null;
                }

                return {
                    page: Number(result.groups.page),
                    size: Number(result.groups.size),
                    total: Number(result.groups.total),
                    pages:
                        Math.floor(
                            Number(result.groups.total) /
                                (Number(result.groups.size) || 1)
                        ) + 1,
                };
            };

            return {
                ...state,
                serviceCardsListPagination:
                    extractPagination(headers) ||
                    state.serviceCardsListPagination,
                serviceCardsList: data,
                loadingServiceCards: false,
            };
        },
        [combineActions(
            GET_SERVICECARD_FILE_REQUEST,
            GET_SERVICECARD_FILE_SUCCESS
        )]: (state, { type }) => {
            return {
                ...state,
                loadingFile:
                    type === GET_SERVICECARD_FILE_REQUEST ? true : null,
            };
        },
        [LOCATION_CHANGE]: state => {
            return {
                ...state,
                cardData: {},
            };
        },
    },
    initialState
);

export default serviceReducer;

import { array, bool, func, object, shape } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    changeSubCategoriesRoute,
    getCategories,
    getServiceCardsList,
    getSubCategoriesList,
} from '../../features/service/actions';
import { subCategoriesListSelector } from '../../features/service/selectors';

import { Container } from '../../components/Container';
import { ServiceCardsList } from './components/ServiceCardsList';
import SubCategoriesList from './components/SubCategoriesList';
import SearchBar from '../../components/SearchBar';

const SubCategories = ({
    getCategories,
    getSubCategoriesList,
    subCategoriesList,
    match: {
        params: { categoryID, subCategoryID },
    },
    getServiceCardsList,
    serviceCardsList,
    serviceCardsListPagination,
    changeSubCategoriesRoute,
    loadingServiceCards,
    serviceCards,
}) => {
    useEffect(() => {
        getSubCategoriesList(categoryID);
    }, [getSubCategoriesList, categoryID]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <>
            <SearchBar />
            <Container>
                <SubCategoriesList
                    subCategoriesList={subCategoriesList}
                    subCategoryID={subCategoryID}
                    categoryID={categoryID}
                    changeSubCategoriesRoute={changeSubCategoriesRoute}
                    getServiceCardsList={getServiceCardsList}
                />
                <ServiceCardsList
                    categoryID={categoryID}
                    serviceCards={serviceCards}
                    serviceCardsList={serviceCardsList}
                    subCategoryID={subCategoryID}
                    subCategoriesList={subCategoriesList}
                    loadingServiceCards={loadingServiceCards}
                    serviceCardsListPagination={serviceCardsListPagination}
                    getServiceCardsList={getServiceCardsList}
                />
            </Container>
        </>
    );
};

SubCategories.propTypes = {
    getCategories: func.isRequired,
    getSubCategoriesList: func.isRequired,
    subCategoriesList: array,
    getServiceCardsList: func.isRequired,
    serviceCardsList: array,
    serviceCards: array,
    changeSubCategoriesRoute: func.isRequired,
    match: shape({ params: object }),
    loadingServiceCards: bool,
    serviceCardsListPagination: object,
};

export default connect(
    state => ({ ...subCategoriesListSelector(state) }),
    {
        getCategories,
        getSubCategoriesList,
        getServiceCardsList,
        changeSubCategoriesRoute,
    }
)(SubCategories);

import { array, bool, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Loader } from '../../../../components/Loader';

import { map, truncate } from 'lodash';
import { urlIdMapper } from '../../../../services/mappers';

import './styles.scss';
import { buildSubcategoryPath } from '../../../../services/path-builders';

export const SubCategoriesList = ({
    subCategoriesList,
    categoryID,
    categories,
    loading,
}) => {
    const subcategoryDescription = urlIdMapper(categories, categoryID);

    return (
        <div className="col-12 col-lg-8 col-xl-9">
            <Loader className="subcategories-list" loading={loading}>
                {map(subcategoryDescription, ({ name, description }) => {
                    return (
                        <React.Fragment key={categoryID}>
                            <h1 className="subcategories-list__heading">
                                {name}
                            </h1>
                            <p className="subcategories-list__description">
                                {description}
                            </p>
                        </React.Fragment>
                    );
                })}
                <span className="subcategories-list__title">Podkategorie</span>
                {map(subCategoriesList, ({ name, id }) => {
                    return (
                        <Link
                            to={buildSubcategoryPath(categoryID, id)}
                            key={id}
                            className="subcategories-list__link"
                        >
                            {truncate(name, {
                                length: 150,
                            })}
                        </Link>
                    );
                })}
            </Loader>
        </div>
    );
};

SubCategoriesList.propTypes = {
    subCategoriesList: array,
    categoryID: string.isRequired,
    categories: array,
    loading: bool,
};

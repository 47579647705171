import { handleActions, combineActions } from 'redux-actions';
import { REDIRECT_CALL_REQUEST, REDIRECT_CALL_SUCCESS } from './constants';

const initialState = {
    redirectURL: '',
};

const applicationReducer = handleActions(
    {
        [combineActions(REDIRECT_CALL_REQUEST, REDIRECT_CALL_SUCCESS)]: (
            state,
            { type, payload }
        ) => {
            return {
                ...state,
                redirectURL: type === REDIRECT_CALL_REQUEST ? '' : payload,
            };
        },
    },
    initialState
);

export default applicationReducer;

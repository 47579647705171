import React from 'react';
import './styles.scss';
import { func, number } from 'prop-types';
import { changeServiceBaseFontSize } from '../../../../features/service/actions';
import { connect } from 'react-redux';

class FontChange extends React.Component {
    render() {
        const currentFontSize = this.props.selectedBaseFontSize;
        return (
            <div className="font-change">
                {[1, 2, 3].map(no => {
                    return (
                        <span
                            key={no}
                            className={
                                `font-change__${no} ` +
                                (currentFontSize === no
                                    ? 'font-change__active'
                                    : '')
                            }
                            onClick={() => this.changeFont(no)}
                        >
                            A
                        </span>
                    );
                })}
            </div>
        );
    }

    changeFont = no => {
        this.props.changeServiceBaseFontSize(no);
    };
}

FontChange.propTypes = {
    selectedBaseFontSize: number.isRequired,
    changeServiceBaseFontSize: func,
};

export default connect(
    state => state,
    {
        changeServiceBaseFontSize,
    }
)(FontChange);

import { array, func, bool } from 'prop-types';
import React from 'react';
import { map } from 'lodash';

import { File } from './components/File';

export const Attachments = ({ attachments, getServiceCardFile }) => {
    return (
        <div className="sidebar-block__attachment">
            {map(attachments, ({ name, id }) => {
                return (
                    <File
                        key={id}
                        name={name}
                        id={id}
                        getServiceCardFile={getServiceCardFile}
                    />
                );
            })}
        </div>
    );
};

Attachments.propTypes = {
    attachments: array.isRequired,
    getServiceCardFile: func.isRequired,
    loadingFile: bool,
};

import { array, bool, func, string } from 'prop-types';
import { Redirect } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import { updateSearchQuery, } from '../../../../features/search/actions';
import { searchDashboardSelector } from '../../../../features/search/selectors';

import './styles.scss';
import Search from "../../../../components/Search";

class DashboardSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        };
    }

    componentDidMount() {
        this.props.updateSearchQuery('');
    }

    render() {
        const {
            searchQuery,
            autocompleteList,
            loading,
            noResultsReason
        } = this.props;

        if (this.state.redirect) {
            return (
                <Redirect push to={'/search-results?query=' + searchQuery} />
            );
        }

        return (
            <div className="dashboard-search">
                <div className="dashboard-search__query">
                    <Search
                        searchQuery={searchQuery}
                        loading={loading}
                        autocompleteList={autocompleteList}
                        noResultsReason={noResultsReason}
                        redirect={this.state.redirect}
                        updateSearchQuery={this.props.updateSearchQuery}
                    />
                </div>
            </div>
        );
    }
}

DashboardSearch.propTypes = {
    updateSearchQuery: func.isRequired,
    searchQuery: string,
    loading: bool,
    noResultsReason: string,
    autocompleteList: array,
};

export default connect(
    state => ({ ...searchDashboardSelector(state) }),
    {
        updateSearchQuery,
    }
)(DashboardSearch);

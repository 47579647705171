import React from 'react';
import { string } from 'prop-types';
import icon from './icon.svg';

const SourceAndLicense = ({ content }) => {
    if (!content) {
        return '';
    }

    return (
        <div className="content__source-and-license">
            <hr />
            <div className="content__source-and-license__main">
                <img
                    alt=""
                    src={icon}
                    className="content__source-and-license__icon"
                />
                <p
                    className="content__source-and-license__content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    );
};

SourceAndLicense.propTypes = {
    content: string.isRequired,
};

export default SourceAndLicense;

import { array, string } from 'prop-types';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { map, trim } from 'lodash';
import { Legal } from '../Legal';
import CardHeader from '../CardHeader';
import SourceAndLicense from '../SourceAndLicense';

export const ContentIssueHandle = ({
    content,
    title,
    description,
    legal,
    modified,
    sourceAndLicense,
}) => {
    const hash = id =>
        document.getElementById(`${id}`).scrollIntoView({
            behavior: 'smooth',
        });

    return (
        <div className="content">
            <CardHeader title={title} modified={modified} />
            <div className="no-print">
                <p>Na skróty:</p>
                <div className="content__links">
                    {map(content, ({ header, no }) => {
                        return (
                            <span key={no} onClick={() => hash(no)}>
                                <ChevronRight size={13} /> {trim(header, ':')}
                            </span>
                        );
                    })}
                </div>
            </div>
            <h2 className="content__header">Informacje ogólne</h2>
            <p className="content__description">{description}</p>

            {map(content, ({ header, body, no }) => {
                const createMarkup = () => {
                    return { __html: body };
                };

                return (
                    <div key={no}>
                        <h3 className="content__header" id={no}>
                            {header}
                        </h3>
                        <p
                            className="content__body"
                            dangerouslySetInnerHTML={createMarkup()}
                        />
                    </div>
                );
            })}
            <Legal legal={legal} />
            <SourceAndLicense content={sourceAndLicense} />
        </div>
    );
};

ContentIssueHandle.propTypes = {
    content: array.isRequired,
    title: string,
    description: string,
    legal: array,
    modified: string,
    sourceAndLicense: string,
};

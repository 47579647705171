import { saveAs } from 'file-saver';

export const file = contentDisposition => {
    const match = contentDisposition.match(
        /filename[^;=\n]*="?(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n"?]*))/iu
    );
    return match.filter((name, index) => name !== undefined && index > 0)[0];
};

export const save = (data, headers) => {
    const fileName = decodeURIComponent(
        file(headers['content-disposition']).replace(/\+/g, ' ')
    );
    const type = headers['content-type'];

    const blob = new Blob([data], { type: type });
    saveAs(blob, fileName);
};

export default save;

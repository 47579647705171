import { array, bool, func, object, oneOfType, string } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getServiceCard,
    getServiceCardFile,
} from '../../features/service/actions';
import { cardsSelector } from '../../features/service/selectors';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import ServiceCardContent from './components/ServiceCardContent';
import { ServiceCardInfo } from './components/ServiceCardInfo';
import './styles.scss';
import { BREADCRUMBS } from '../../constants/breadcrumbs';
import {
    buildCategoryPath,
    buildSubcategoryPath,
} from '../../services/path-builders';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import SearchBar from '../../components/SearchBar';
import { isEmptyString, isNullOrUndefined } from '../../core/utils';
import { ExternalServiceCall } from './components/ExternalServiceCall';
import Page404 from '../Page404';

const ServiceCard = ({
    match,
    location,
    getServiceCard,
    type,
    kind,
    title,
    modified,
    description,
    url,
    legal,
    contact,
    loadingCard,
    content,
    sourceAndLicense,
    relatedServiceCards,
    attachments,
    getServiceCardFile,
    loadingFile,
    categories,
    cardNotFound,
    history,
}) => {
    useEffect(() => {
        const { id } = match.params;
        getServiceCard(id);
    }, [match.params, getServiceCard]);

    if (cardNotFound) {
        return (
            <>
                <SearchBar />
                <Page404 history={history} />
            </>
        );
    }

    return (
        <>
            <SearchBar />
            <Loader loading={loadingCard}>
                <Container>
                    {renderBreadCrumbs(categories, location, title)}
                    <ServiceCardContent
                        kind={kind}
                        title={title}
                        description={description}
                        content={content}
                        legal={legal}
                        modified={modified}
                        sourceAndLicense={sourceAndLicense}
                    />
                    <ServiceCardInfo
                        contact={contact}
                        relatedServiceCards={relatedServiceCards}
                        attachments={attachments}
                        getServiceCardFile={getServiceCardFile}
                        loadingFile={loadingFile}
                    />

                    {!isNullOrUndefined(url) && !isEmptyString(url.uri) && (
                        <div className="service-card__external-service-link-area">
                            <div className="container">
                                <ExternalServiceCall url={url} type={type} />
                            </div>
                        </div>
                    )}
                </Container>
            </Loader>
        </>
    );
};

const renderBreadCrumbs = (categories, location, title) => {
    if (!categories) {
        return '';
    }

    const selectedSubcategoryId = new URLSearchParams(location.search).get(
        'subcategoryId'
    );

    const links = [...BREADCRUMBS.CATEGORIES.links];

    let linksData = {
        category: {
            id: null,
            name: null,
        },
        subcategory: {
            id: null,
            name: null,
        },
    };

    if (selectedSubcategoryId) {
        for (let i in categories) {
            for (let j in categories[i].subcategories) {
                if (
                    categories[i].subcategories[j].id === selectedSubcategoryId
                ) {
                    linksData = {
                        category: {
                            id: categories[i].id,
                            name: categories[i].name,
                        },
                        subcategory: {
                            id: categories[i].subcategories[j].id,
                            name: categories[i].subcategories[j].name,
                        },
                    };
                }
            }
        }
    }

    if (!linksData.category.id) {
        linksData = {
            category: {
                id: categories[0].id,
                name: categories[0].name,
            },
            subcategory: {
                id: categories[0].subcategories[0].id,
                name: categories[0].subcategories[0].name,
            },
        };
    }

    links.push({
        link: buildCategoryPath(linksData.category.id),
        text: linksData.category.name,
    });

    links.push({
        link: buildSubcategoryPath(
            linksData.category.id,
            linksData.subcategory.id
        ),
        text: linksData.subcategory.name,
    });

    return <Breadcrumbs links={links} current={title || ''} />;
};

ServiceCard.propTypes = {
    attachments: array,
    categories: array,
    getServiceCard: func.isRequired,
    getServiceCardFile: func.isRequired,
    match: object.isRequired,
    content: oneOfType([string, array]).isRequired,
    legal: array,
    type: string.isRequired,
    kind: string.isRequired,
    title: string,
    description: string,
    url: object,
    contact: object.isRequired,
    loadingCard: bool.isRequired,
    relatedServiceCards: array,
    loadingFile: bool,
    modified: string,
    sourceAndLicense: string,
    location: object,
    cardNotFound: bool,
    history: object,
};

export default connect(
    state => ({ ...cardsSelector(state) }),
    {
        getServiceCard,
        getServiceCardFile,
    }
)(ServiceCard);

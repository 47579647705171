import { bool, number, string } from 'prop-types';
import React from 'react';

export const Count = ({ loading, count, title, className }) => {
    return (
        <div className="col-12">
            <div className={className}>
                {title}
                {loading ? (
                    <React.Fragment> ...</React.Fragment>
                ) : (
                    <React.Fragment> {count}</React.Fragment>
                )}
            </div>
        </div>
    );
};

Count.propTypes = {
    loading: bool,
    count: number.isRequired,
    title: string.isRequired,
    className: string,
};

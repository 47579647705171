import { array, bool, func, string } from 'prop-types';
import React from 'react';
import { map } from 'lodash';

import { Radio } from './components/Radio';
import { Select } from './components/Select';

export const TabGroup = ({
    items,
    onChange,
    className,
    isMobile,
    title,
    label,
}) => {
    return (
        <div className="col-12">
            <div className={className}>
                <div className="tab-group-title">{title}</div>
                <form className="tab-group">
                    {!isMobile ? (
                        <React.Fragment>
                            {map(items, ({ value, name, id, isSelected }) => {
                                return (
                                    <Radio
                                        key={id}
                                        id={id}
                                        name={name}
                                        value={value}
                                        isSelected={isSelected}
                                        onChange={onChange}
                                    />
                                );
                            })}
                        </React.Fragment>
                    ) : (
                        <Select
                            options={items}
                            onChange={onChange}
                            label={label}
                        />
                    )}
                </form>
            </div>
        </div>
    );
};

TabGroup.propTypes = {
    onChange: func,
    items: array.isRequired,
    className: string,
    isMobile: bool.isRequired,
    title: string,
    label: string,
};

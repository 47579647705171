import { bool, func, number } from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

import './styles.scss';

export const Pagination = ({ page = 1, pages, setPage, hidden = true }) => {
    return (
        <div
            className={classNames(
                'col-12 col-lg-8 offset-lg-4 col-xl-9 offset-xl-3',
                {
                    pagination__hidden: hidden,
                }
            )}
        >
            <ReactPaginate
                pageCount={pages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={5}
                containerClassName="pagination"
                pageClassName="pagination__button"
                onPageChange={({ selected }) => setPage(selected)}
                previousLabel={page > 1 ? '← Poprzednia' : ''}
                nextLabel={page < pages ? 'Następna →' : ''}
                forcePage={page - 1}
            />
        </div>
    );
};

Pagination.propTypes = {
    hidden: bool.isRequired,
    pages: number.isRequired,
    page: number.isRequired,
    setPage: func.isRequired,
};

import { string } from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import './styles.scss';

export const Title = ({ title, className }) => {
    return (
        <div className={classNames('col-12', className)}>
            <div className="title">{title}</div>
        </div>
    );
};

Title.propTypes = {
    title: string.isRequired,
    className: string,
};

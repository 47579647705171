import { string, number, func, bool } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const Radio = ({ id, value, isSelected, name, onChange }) => {
    return (
        <label
            key={id}
            className={classNames('tab-group__label', {
                'tab-group__label--active': isSelected,
            })}
        >
            <input
                type="radio"
                value={value}
                name="SEARCH_TYPES"
                checked={isSelected}
                className="tab-group__input"
                onChange={() => onChange({ value })}
            />
            {name}
        </label>
    );
};

Radio.propTypes = {
    id: number.isRequired,
    value: string.isRequired,
    isSelected: bool,
    name: string.isRequired,
    onChange: func.isRequired,
};

import { bool, node, string } from 'prop-types';
import React from 'react';

export const Container = ({ children, wide, className }) => {
    let classes = wide ? 'container-fluid' : 'container';

    if (className) {
        classes += ' ' + className;
    }

    return (
        <div className={classes}>
            <div className="row">{children}</div>
        </div>
    );
};

Container.propTypes = {
    children: node.isRequired,
    wide: bool,
    className: string,
};

Container.defaultProps = {
    wide: false,
};

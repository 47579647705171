import React from 'react';
import { Printer } from 'react-feather';
import { isMobile } from '../../../../../../services/mobile';
import { format } from 'date-fns';
import { string } from 'prop-types';

const CardHeader = ({ modified, title }) => {
    return (
        <>
            <h1 className="content__title">
                {title}
                {!isMobile() && (
                    <div
                        className="content__print no-print"
                        onClick={() => window.print()}
                    >
                        <Printer size={20} color="#5B626C" />
                    </div>
                )}
            </h1>
            {modified && (
                <div className="content__last-update">
                    Ostatnia aktualizacja:{' '}
                    {format(new Date(modified), 'dd.MM.yyyy')}
                </div>
            )}
        </>
    );
};

CardHeader.propTypes = {
    title: string,
    modified: string,
};

export default CardHeader;

import { string } from 'prop-types';
import React from 'react';

import './styles.scss';

export const NoContent = ({ text }) => {
    return <div className="no-data">{text}</div>;
};

NoContent.propTypes = {
    text: string.isRequired,
};

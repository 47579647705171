import { array, string } from 'prop-types';
import React from 'react';
import { Legal } from '../Legal';
import CardHeader from '../CardHeader';
import SourceAndLicense from '../SourceAndLicense';

export const ContentService = ({
    title,
    description,
    content,
    legal,
    modified,
    sourceAndLicense,
}) => {
    const createMarkup = () => {
        return { __html: content };
    };

    return (
        <React.Fragment>
            <CardHeader title={title} modified={modified} />
            <p className="content__description">{description}</p>
            <div
                className="content__body"
                dangerouslySetInnerHTML={createMarkup()}
            />
            <Legal legal={legal} />
            <SourceAndLicense content={sourceAndLicense} />
        </React.Fragment>
    );
};

ContentService.propTypes = {
    content: string.isRequired,
    title: string,
    description: string,
    sourceAndLicense: string,
    legal: array,
    modified: string,
};

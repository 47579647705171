import { take } from 'lodash';

export const searchDashboardSelector = ({
    search: { searchQuery, autocompleteList, loading, noResultsReason },
}) => ({
    searchQuery,
    autocompleteList: take(autocompleteList, 5),
    loading,
    noResultsReason,
});

import { node, string, object } from 'prop-types';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { isMobile } from '../../services/mobile';

import arrowRight from './arrow-right.svg';
import './styles.scss';

export const Sidebar = ({ children, title, icon }) => {
    const [filtersVisibility, toggleFilters] = useState(!isMobile());

    const toggle = () => toggleFilters(!filtersVisibility);

    return (
        <div className="sidebar-block">
            <div className="sidebar-block__title">
                {icon} <span>{title}</span>
                <img
                    src={arrowRight}
                    alt={title}
                    className={classNames('sidebar-block__toggle', {
                        'sidebar-block__toggle--visible': filtersVisibility,
                    })}
                    onClick={toggle}
                />
            </div>
            <CSSTransition
                in={filtersVisibility}
                timeout={375}
                classNames="filters-block__items"
                unmountOnExit
            >
                <div className="sidebar-block__items">{children}</div>
            </CSSTransition>
        </div>
    );
};

Sidebar.propTypes = {
    children: node.isRequired,
    title: string.isRequired,
    icon: object.isRequired,
};

import React from 'react';

import './styles.scss';

export const Spinner = () => {
    return (
        <div className="spinner">
            <div className="spinner__step spinner__step-one" />
            <div className="spinner__step spinner__step-two" />
            <div className="spinner__step spinner__step-three" />
            <div className="spinner__step spinner__step-four" />
            <div className="spinner__step spinner__step-five" />
        </div>
    );
};

import { shape, string } from 'prop-types';
import React from 'react';
import { AtSign, Clock, Home, PhoneCall, User } from 'react-feather';
import { isEmptyString } from '../../../../../../core/utils';

export const Entity = ({
    contact: {
        name,
        phoneNumber,
        emailAddress,
        address,
        postcode,
        openingHours,
    },
}) => {
    return (
        <div className="responsible-entity">
            <h4 className="responsible-entity__title">Podmiot odpowiedzialny</h4>
            {!isEmptyString(name) && (
                <div className="mt-2">
                    <div className="responsible-entity__group">
                        <User size={24} color="#5B626C" />
                        <h6>Nazwa Podmiotu</h6>
                    </div>
                    <p className="responsible-entity__item">{name}</p>
                </div>
            )}
            {!isEmptyString(phoneNumber) && (
                <div className="mt-2">
                    <div className="responsible-entity__group">
                        <PhoneCall size={24} color="#5B626C" />
                        <h6>Numer telefonu</h6>
                    </div>
                    <p className="responsible-entity__item">{phoneNumber}</p>
                </div>
            )}
            {!isEmptyString(emailAddress) && (
                <div className="mt-2">
                    <div className="responsible-entity__group">
                        <AtSign size={24} color="#5B626C" />
                        <h6>Adres e-mail</h6>
                    </div>
                    <p className="responsible-entity__item">
                        <a
                            href={`mailto:${emailAddress}`}
                            className="responsible-entity__email"
                        >
                            {emailAddress}
                        </a>
                    </p>
                </div>
            )}
            {!isEmptyString(address) && (
                <div className="mt-2">
                    <div className="responsible-entity__group">
                        <Home size={24} color="#5B626C" />
                        <h6>Adres siedziby</h6>
                    </div>
                    <p className="responsible-entity__item">{address}</p>
                </div>
            )}
            {!isEmptyString(openingHours) && (
                <div className="mt-2">
                    <div className="responsible-entity__group">
                        <Clock size={24} color="#5B626C" />
                        <h6>Godziny otwarcia</h6>
                    </div>
                    <p className="responsible-entity__item">{openingHours}</p>
                </div>
            )}
        </div>
    );
};

Entity.propTypes = {
    contact: shape({
        name: string,
        phoneNumber: string,
        emailAddress: string,
        address: string,
        postcode: string,
        openingHours: string,
    }),
};

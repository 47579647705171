import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../services/history';

import application from '../features/application';
import service from '../features/service';
import search from '../features/search';

const rootReducer = combineReducers({
    application,
    service,
    search,
    router: connectRouter(history),
});

export default rootReducer;

import { array, oneOfType, string } from 'prop-types';
import React from 'react';

import { ContentIssueHandle } from './components/ContentIssueHandle';
import { ContentService } from './components/ContentService';

import './styles.scss';
import { ServiceCardKindEnum } from "../../../../enum/service_card_kind_enum";

const ServiceCardContent = ({
    content,
    kind,
    title,
    description,
    sourceAndLicense,
    legal,
    modified,
}) => {
    return (
        <div className="col-12 col-lg-8 col-xl-9">
            {kind === ServiceCardKindEnum.EXTENDED && (
                <ContentIssueHandle
                    content={content}
                    title={title}
                    description={description}
                    legal={legal}
                    modified={modified}
                    sourceAndLicense={sourceAndLicense}
                />
            )}
            {kind === ServiceCardKindEnum.SIMPLE && (
                <ContentService
                    title={title}
                    description={description}
                    content={content}
                    legal={legal}
                    modified={modified}
                    sourceAndLicense={sourceAndLicense}
                />
            )}
        </div>
    );
};

ServiceCardContent.propTypes = {
    content: oneOfType([array, string]).isRequired,
    kind: string.isRequired,
    title: string,
    description: string,
    sourceAndLicense: string,
    legal: array,
    modified: string,
};

export default ServiceCardContent;

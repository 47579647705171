const SEARCH_RESULTS = {
    links: [
        {
            text: 'Strona główna',
            link: '/',
        },
    ],
    current: 'Wyniki wyszukiwania',
};

const CATEGORIES = {
    links: [
        {
            text: 'Strona główna',
            link: '/',
        },
    ],
};

export const BREADCRUMBS = {
    SEARCH_RESULTS,
    CATEGORIES,
};

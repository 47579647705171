import React from 'react';
import { Container } from '../../components/Container';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Title } from '../../components/Title';
import SearchFilters from './components/SearchFilters';
import SearchItems from './components/SearchItems';

import { BREADCRUMBS } from '../../constants/breadcrumbs';

import './styles.scss';
import { searchResultsCall } from '../../features/search/repository';
import { debounce, filter, find, includes, map } from 'lodash';
import { tabGroupData } from '../../constants/data';
import { Pagination } from '../../components/Pagination';
import { MAX_ARTICLES_PER_PAGE } from '../../constants/search';
import { redirectCall } from '../../features/application/actions';
import { isMobile } from '../../services/mobile';
import { object } from 'prop-types';
import { UrlParamsManipulator } from './url_params_manipulator';
import SearchBar from "../../components/SearchBar";
import { SearchBoxType } from "../../components/Search";
import { ClientConfig } from "../../clientConfigApplicator";

class SearchResults extends React.Component {
    urlParamsManipulator;

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            searchQuery: '',
            categories: [],
            keywords: [],
            types: tabGroupData(),
            page: 1,
            pages: 0,
            total: 0,
            ...props,
        };
        this.urlParamsManipulator = new UrlParamsManipulator(props.history);
    }

    render() {
        let { links, current } = BREADCRUMBS.SEARCH_RESULTS;

        if (document.referrer && new URL(document.referrer).hostname === new URL(ClientConfig.homePageUrl).hostname) {
            links = links.slice();
            links.unshift({link: ClientConfig.homePageUrl, text: new URL(ClientConfig.homePageUrl).hostname});
        }

        return (
            <>
                <SearchBar
                    type={SearchBoxType.SEARCH_RESULTS}
                    searchQuery={this.state.searchQuery}
                    forcedSearchQuery={this.state.searchQuery}
                    onSearchSubmit={this.reloadResults}
                    onSearchQueryChange={this.onSearchQueryChange}
                />
                <Container>
                    <Breadcrumbs links={links} current={current} />
                    <Title title="Wyniki wyszukiwania" />
                    <SearchFilters
                        categories={this.state.categories}
                        keywords={this.state.keywords}
                        types={this.state.types}
                        selectSingleCategory={this.onSelectSingleCategory}
                        selectSingleKeyword={this.onSelectSingleKeyword}
                        selectSingleType={this.onSelectSingleType}
                        clearFilters={this.onClearFilters}
                        disabled={false}
                        count={this.state.total}
                        loadingResults={this.state.loadingResults}
                        isMobile={isMobile()}
                        urlParamsManipulator={this.urlParamsManipulator}
                    />
                    <SearchItems
                        results={this.state.articles}
                        noResultsReason={this.state.noResultsReason}
                        redirectCall={redirectCall}
                        loadingResults={this.state.loadingResults}
                    />
                    <Pagination
                        page={this.state.page}
                        pages={this.state.pages}
                        setPage={this.onSetPage}
                        hidden={this.state.articles.length === 0}
                    />
                </Container>
                </>
        );
    }

    componentDidMount() {
        this.reloadResults();
        window.addEventListener('popstate', () => {
            this.reloadResults();
        });
    }

    onSearchQueryChange = searchQuery => {
        this.setState({ searchQuery: searchQuery });
        this.debouncedSearchQueryChange(searchQuery);
    };

    debouncedSearchQueryChange = debounce(searchQuery => {
        this.urlParamsManipulator.changeSearchQuery(searchQuery);
        this.reloadResults();
    }, 400);

    onSelectSingleCategory = ({ name, isSelected }) => {
        if (isSelected) {
            this.urlParamsManipulator.addCategory(name);
        } else {
            this.urlParamsManipulator.removeCategory(name);
        }
        this.reloadResults();
    };

    onSelectSingleKeyword = ({ name, isSelected }) => {
        if (isSelected) {
            this.urlParamsManipulator.addKeyword(name);
        } else {
            this.urlParamsManipulator.removeKeyword(name);
        }
        this.reloadResults();
    };

    onSelectSingleType = ({ value }) => {
        const typeName = find(tabGroupData(), { value: value }).name;
        this.urlParamsManipulator.changeType(typeName);
        this.reloadResults();
    };

    onClearSearchQuery = () => {
        this.urlParamsManipulator.clearSearchQuery();
        this.reloadResults();
    };

    onClearFilters = () => {
        this.urlParamsManipulator.resetFilters();
        this.reloadResults();
    };

    onSetPage = pageIndex => {
        this.urlParamsManipulator.changePage(pageIndex + 1);
        this.reloadResults();
    };

    reloadResults = () => {
        const paramsReceiver = this.urlParamsManipulator;

        const searchQuery = paramsReceiver.getSearchQuery();

        const selectedCategoryNames = paramsReceiver.getCategories();
        const selectedKeywordsNames = paramsReceiver.getKeywords();
        const selectedTypeName = paramsReceiver.getType();
        const page = paramsReceiver.getPage();

        const selectedTypeValue = find(
            markSelectedTypes(tabGroupData(), selectedTypeName),
            type => {
                return type.isSelected;
            }
        ).value;

        const newState = {
            searchQuery: searchQuery,
            types: markSelectedTypes(this.state.types, selectedTypeName),
            categories: markSelectedCategories(
                this.state.categories,
                selectedCategoryNames
            ),
            keywords: markSelectedKeywords(
                this.state.keywords,
                selectedKeywordsNames
            ),
            articles: [],
            total: 0,
            loadingResults: searchQuery.length >= 3,
            page: page,
        };

        this.setState(newState);

        if (searchQuery.length < 3) {
            this.setState({
                categories: [],
                keywords: [],
            });
            return;
        }

        searchResultsCall(
            searchQuery,
            selectedCategoryNames,
            selectedKeywordsNames,
            selectedTypeValue,
            page,
        ).then(articlesSearchSummary => {
            this.setState({
                loadingResults: false,
                noResultsReason: articlesSearchSummary.noResultsReason,
                articles: articlesSearchSummary.articles,
                total: articlesSearchSummary.total,
                categories: markSelectedCategories(
                    filter(
                        articlesSearchSummary.categories,
                        ({ name }) => name.trim() !== ''
                    ),
                    selectedCategoryNames
                ),
                keywords: markSelectedKeywords(
                    filter(
                        articlesSearchSummary.keywords,
                        ({ name }) => name.trim() !== ''
                    ),
                    selectedKeywordsNames
                ),
                pages: Math.ceil(
                    articlesSearchSummary.total / MAX_ARTICLES_PER_PAGE
                ),
            });
        });
    };
}

SearchResults.propTypes = {
    history: object.isRequired,
};

const markSelectedTypes = (types, selectedTypeName) => {
    if (!selectedTypeName) {
        selectedTypeName = tabGroupData()[0].name;
    }
    return markSelectedFilterItem(types, [selectedTypeName]);
};

const markSelectedKeywords = (keywords, selectedNames) => {
    return markSelectedFilterItem(keywords, selectedNames);
};

const markSelectedCategories = (categories, selectedNames) => {
    return markSelectedFilterItem(categories, selectedNames);
};

const markSelectedFilterItem = (items, selectedNames) => {
    return map(items, item => {
        let isSelected = false;
        if (includes(selectedNames, item.name)) {
            isSelected = true;
        }
        return { ...item, isSelected: isSelected };
    });
};

export default SearchResults;

import { func, node, bool, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const Button = ({
    onClick,
    loading,
    children,
    primary,
    alternate,
    inverted,
    big,
    type,
    className,
    disabled,
}) => {
    return (
        <button
            className={classNames('button', className, {
                'button--is-loading': loading,
                button__primary: primary,
                button__alternate: alternate,
                button__inverted: inverted,
                button__big: big,
                'button--disabled': disabled,
            })}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    onClick: func,
    children: node.isRequired,
    loading: bool,
    primary: bool,
    inverted: bool,
    big: bool,
    alternate: bool,
    type: string,
    className: string,
    disabled: bool,
};

Button.defaultProps = {
    onClick: () => {},
    loading: false,
    primary: false,
    alternate: false,
    big: false,
    inverted: false,
    type: 'button',
    disabled: false,
};

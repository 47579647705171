import { object, string } from 'prop-types';
import React from 'react';

import './styles.scss';
import arrowImg from './icon-arrow.svg';
import { ServiceCardTypeEnum } from "../../../../enum/service_card_type_enum";

export const ExternalServiceCall = ({
    url: { uri },
    type
}) => {
    return (
        <a
            href={uri}
            className="call-link"
            target="_blank"
            rel="noopener noreferrer"
        >
            {
                type === ServiceCardTypeEnum.SERVICE
                && 'PRZEJDŹ DO USŁUGI ON-LINE'
            }
            {
                type === ServiceCardTypeEnum.ISSUE_HANDLE
                && 'ZAŁATW SPRAWĘ ON-LINE'
            }

            &nbsp;
            <img src={arrowImg} alt=''/>
        </a>
    );
};

ExternalServiceCall.propTypes = {
    url: object.isRequired,
    type: string.isRequired,
};

import { bool, node, string } from 'prop-types';
import React from 'react';

import { Spinner } from '../Spinner';

export const Loader = ({ children, className, loading }) => {
    return (
        <div className={className}>
            {loading ? (
                <Spinner />
            ) : (
                <React.Fragment>{children}</React.Fragment>
            )}
        </div>
    );
};

Loader.propTypes = {
    loading: bool,
    className: string,
    children: node,
};

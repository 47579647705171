export const buildCategoryPath = categoryId => {
    return `/categories/${categoryId}`;
};

export const buildSubcategoryPath = (categoryId, subcategoryId) => {
    return `/subcategories/${subcategoryId}/${categoryId}`;
};

export const buildServiceCardPath = (serviceCardId, subcategoryId) => {
    const path = `/service-card/${serviceCardId}`;

    return subcategoryId ? path + `?subcategoryId=${subcategoryId}` : path;
};

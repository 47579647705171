import { array, oneOfType, string } from 'prop-types';
import React from 'react';

import { serviceCardTypeMapper } from '../../../../../services/mappers';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';

export const ServiceCardsListItem = ({
    title,
    serviceCardType,
    description,
    redirectUrl,
    categories,
}) => {
    return (
        <div className="search-item">
            <Link className="search-item__title" to={redirectUrl}>
                {title}
            </Link>
            <div className="search-item__subcategory">
                {serviceCardTypeMapper(serviceCardType)}
                <span className="text-uppercase">
                    {categories.length > 0 ? ' - ' + categories : ''}
                </span>
            </div>
            <div className="search-item__description">
                {truncate(description, { length: 278 })}
            </div>
        </div>
    );
};

ServiceCardsListItem.propTypes = {
    title: string.isRequired,
    serviceCardType: string.isRequired,
    description: string.isRequired,
    redirectUrl: string.isRequired,
    categories: oneOfType([string, array]),
};

import { array, bool, func, object } from 'prop-types';
import React from 'react';
import { Download, Link2 } from 'react-feather';
import { isEmpty } from 'lodash';

import { Entity } from './components/Entity';
import { RelatedServiceCards } from './components/RelatedServiceCards';
import { Attachments } from './components/Attachments';
import { Sidebar } from '../../../../components/Sidebar';

import './styles.scss';

export const ServiceCardInfo = ({
    contact,
    relatedServiceCards,
    attachments,
    getServiceCardFile,
    loadingFile,
}) => {
    return (
        <div className="col-12 col-lg-4 col-xl-3 no-print">
            {relatedServiceCards.length > 0 && (
                <Sidebar title="Powiązane artykuły" icon={<Link2 />}>
                    <RelatedServiceCards
                        relatedServiceCards={relatedServiceCards}
                    />
                </Sidebar>
            )}
            {attachments.length > 0 && (
                <Sidebar title="Do pobrania" icon={<Download />}>
                    <Attachments
                        attachments={attachments}
                        getServiceCardFile={getServiceCardFile}
                        loadingFile={loadingFile}
                    />
                </Sidebar>
            )}
            {!isEmpty(contact) && <Entity contact={contact} />}
        </div>
    );
};

ServiceCardInfo.propTypes = {
    contact: object,
    relatedServiceCards: array,
    attachments: array,
    getServiceCardFile: func.isRequired,
    loadingFile: bool,
};

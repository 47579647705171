import { createAction } from 'redux-actions';

import { cardRedirectCall } from './repository';
import { constants } from './index';

export const redirectCallRequest = createAction(
    constants.REDIRECT_CALL_REQUEST
);
export const redirectCallSuccess = createAction(
    constants.REDIRECT_CALL_SUCCESS
);

export const redirectCall = (url, onError) => {
    return cardRedirectCall(url)
        .then(({ url }) => {
            window.location.href = url;
        })
        .catch(error => {
            if (onError) {
                onError();
            }
            console.log('redirectCall', error);
        });
};

import { string, array, func } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';

import './styles.scss';

export class Select extends React.Component {
    onInputChange = event => {
        const { value } = event.target;
        const { onChange } = this.props;

        onChange(value);
    };

    render() {
        const { label, className, options } = this.props;
        return (
            <div>
                <label htmlFor="" className="select__label">
                    {label}
                </label>
                <select
                    className={classNames('select', className)}
                    onChange={this.onInputChange}
                    defaultValue=""
                >
                    {map(options, ({ value, id, name, isSelected }) => {
                        return (
                            <option
                                value={value}
                                key={id}
                                selected={isSelected}
                            >
                                {name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}

Select.propTypes = {
    value: string,
    onChange: func.isRequired,
    className: string,
    selectLabel: string,
    options: array.isRequired,
    label: string,
};

import _ from 'lodash';
import { tabGroupData } from '../../constants/data';

export class UrlParamsManipulator {
    history;
    params = {};

    static get CATEGORIES() {
        return 'categories';
    }

    static get KEYWORDS() {
        return 'keywords';
    }

    static get E_FORMS_ONLY() {
        return 'eFormsOnly';
    }

    static get DOWNLOADABLE_FORMS_ONLY() {
        return 'downloadableFormsOnly';
    }

    static get SEARCH_QUERY() {
        return 'query';
    }

    static get PAGE() {
        return 'page';
    }

    static get TYPE() {
        return 'type';
    }

    constructor(history) {
        this.history = history;
        this._syncParams();
    }

    changePage = page => {
        this._setParam(UrlParamsManipulator.PAGE, page);
        this._commit();
    };

    changeEFormsOnly = eFormsOnly => {
        this._setParam(UrlParamsManipulator.E_FORMS_ONLY, eFormsOnly ? 1 : 0);
        this._resetPage();
        this._commit();
    };

    changeDownloadableFormsOnly = downloadableFormsOnly => {
        this._setParam(UrlParamsManipulator.DOWNLOADABLE_FORMS_ONLY, downloadableFormsOnly ? 1 : 0);
        this._resetPage();
        this._commit();
    };

    changeType = type => {
        if (type === tabGroupData()[0].name) {
            type = '';
        }
        this._setParam(UrlParamsManipulator.TYPE, type);
        this._resetPage();
        this._commit();
    };

    changeSearchQuery = query => {
        this._setParam(UrlParamsManipulator.SEARCH_QUERY, query);
        this._clearFilters();
        this._resetPage();
        this._commit();
    };

    addCategory = category => {
        let categories = this.getCategories();
        _.uniq(categories.push(category));
        this._setParam(UrlParamsManipulator.CATEGORIES, categories.join('|'));
        this._resetPage();
        this._commit();
    };

    removeCategory = category => {
        let categories = this.getCategories();
        categories = _.without(categories, category);
        this._setParam(UrlParamsManipulator.CATEGORIES, categories.join('|'));
        this._resetPage();
        this._commit();
    };

    addKeyword = keyword => {
        let keywords = this.getKeywords();
        _.uniq(keywords.push(keyword));
        this._setParam(UrlParamsManipulator.KEYWORDS, keywords.join('|'));
        this._resetPage();
        this._commit();
    };

    removeKeyword = keyword => {
        let keywords = this.getKeywords();
        keywords = _.without(keywords, keyword);
        this._setParam(UrlParamsManipulator.KEYWORDS, keywords.join('|'));
        this._resetPage();
        this._commit();
    };

    resetFilters = () => {
        this._clearFilters();
        this._commit();
    };

    clearSearchQuery = () => {
        this._setParam(UrlParamsManipulator.SEARCH_QUERY, '');
        this._clearFilters();
        this._commit();
    };

    _clearFilters = () => {
        this._setParam(UrlParamsManipulator.KEYWORDS, null);
        this._setParam(UrlParamsManipulator.CATEGORIES, null);
        this._setParam(UrlParamsManipulator.TYPE, null);
        this._setParam(UrlParamsManipulator.E_FORMS_ONLY, null);
        this._setParam(UrlParamsManipulator.KEYWORDS, null);
        this._resetPage();
    };

    _resetPage = () => {
        this._setParam(UrlParamsManipulator.PAGE, '');
    };

    _setParam = (field, value) => {
        this.params[field] = value;
    };

    _commit = () => {
        const queryParams = new URLSearchParams();

        for (let field in this.params) {
            if (this.params[field] !== '' && this.params[field] !== null) {
                queryParams.set(field, this.params[field]);
            }
        }

        this.history.push({
            search: queryParams.toString(),
        });
    };

    getSearchQuery = () => {
        return this.getParam(UrlParamsManipulator.SEARCH_QUERY) || '';
    };

    getCategories = () => {
        const categoriesString =
            this.getParam(UrlParamsManipulator.CATEGORIES) || null;

        if (!categoriesString) {
            return [];
        }

        return categoriesString.split('|');
    };

    getKeywords = () => {
        const keywordsString =
            this.getParam(UrlParamsManipulator.KEYWORDS) || null;

        if (!keywordsString) {
            return [];
        }

        return keywordsString.split('|');
    };

    getEFormsOnly = () => {
        return Boolean(
            Number(this.getParam(UrlParamsManipulator.E_FORMS_ONLY) || 0)
        );
    };

    getDownloadableFormsOnly = () => {
        return Boolean(
            Number(this.getParam(UrlParamsManipulator.DOWNLOADABLE_FORMS_ONLY) || 0)
        );
    };

    getType = () => {
        return this.getParam(UrlParamsManipulator.TYPE) || '';
    };

    getPage = () => {
        return Number(this.getParam(UrlParamsManipulator.PAGE) || 1);
    };

    getParam = name => {
        this._syncParams();
        return this.params[name] || null;
    };

    _syncParams = () => {
        this.params = {};
        const queryParams = new URLSearchParams(this.history.location.search);
        queryParams.forEach((value, key) => {
            this.params[key] = value;
        });
    };
}

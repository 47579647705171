import { BREADCRUMBS } from './constants/breadcrumbs';
import { ArticleTypeNameEnum } from "./enum/article_type_name_enum";

export let ClientConfig = {
    serviceActive: true,
    clientId: '',
    copyrightYear: '',
    shortName: '',
    fullName: '',
    serviceName: '',
    bipUrl: '',
    homePageUrl: '',
    faviconUrl: '',
    dashboardImgUrl: '',
    logoUrl: '',
    serviceLogoUrl: '',
    wwwArticleTypeName: '',
    style: {
      primaryColor: '',
      secondaryColor: '',
      textFont: '',
      titleFont: '',
      searchButtonColor: '',
      sharpCorners: false,
      noSearchboxGradient: false,
      searchButtonIconColor: '',
      greenColor: '',
      blueColor: '',
      grayColor: '',
      sidemenuTextColorOnPrimary: '',
      sidemenuTextColorOnSecondary: '',
    },
};

export const applyConfig = clientConfig => {
    ClientConfig = Object.freeze({
        ...ClientConfig,
        ...clientConfig,
    });
    const headNode = document.getElementsByTagName('head').item(0);
    const styleNode = document.createElement('style');
    const textNode = document.createTextNode(generateStyle(clientConfig));

    styleNode.appendChild(textNode);
    headNode.prepend(styleNode);

    // breadcrumbs
    BREADCRUMBS.CATEGORIES.links[0].text = ClientConfig.serviceName;
    BREADCRUMBS.SEARCH_RESULTS.links[0].text = ClientConfig.serviceName;

    // favicon
    if (ClientConfig.faviconUrl) {
        document
            .getElementById('favicon')
            .setAttribute('href', ClientConfig.faviconUrl);
    }

    //www article type name
    if (ClientConfig.wwwArticleTypeName) {
        ArticleTypeNameEnum.WWW_ARTICLE = ClientConfig.wwwArticleTypeName;
    }
};

const generateStyle = clientConfig => {
    const builder = new StyleBuilder();

    if (clientConfig.style) {
        const style = clientConfig.style;

        if (style.primaryColor) {
            builder.addStyleVar('--primary-color', style.primaryColor);
            builder.addStyleVar('--primary-color-darken-10', lightenColor(style.primaryColor, -10));
            builder.addStyleVar('--primary-color-darken-20', lightenColor(style.primaryColor, -20));
            builder.addStyleVar('--primary-color-lighten-5', lightenColor(style.primaryColor, 5));
            builder.addStyleVar('--primary-color-lighten-10', lightenColor(style.primaryColor, 10));
        }

        if (style.secondaryColor) {
            builder.addStyleVar('--secondary-color', style.secondaryColor);
            builder.addStyleVar('--secondary-color-lighten-10', lightenColor(style.secondaryColor, 10));
        }

        if (style.titleFont) {
            builder.addStyleVar('--title-font', style.titleFont);
        }

        if (style.textFont) {
            builder.addStyleVar('--text-font', style.textFont);
        }

        builder.addStyleVar('--border-radius-enabled', style.sharpCorners ? 0 : 1);


        builder.addColorVar('--search-button-color', style.searchButtonColor, 'var(--primary-color-lighten-10)');
        builder.addColorVar('--search-button-icon-color', style.searchButtonIconColor, 'var(--white-color)');
        builder.addColorVar('--green-color', style.greenColor, '#097241');
        builder.addColorVar('--green-color-darken-10', lightenColor(style.greenColor, -10), '#097241');
        builder.addColorVar('--green-color-darken-20', lightenColor(style.greenColor, -20), '#097241');
        builder.addColorVar('--blue-color', style.blueColor, '#1862c4');
        builder.addColorVar('--blue-color-darken-10', lightenColor(style.blueColor, -10), '#1862c4');
        builder.addColorVar('--gray-color', style.grayColor, '#363636');
        builder.addColorVar('--sidemenu-text-color-on-primary', style.sidemenuTextColorOnPrimary, 'var(--white-color)');
        builder.addColorVar('--sidemenu-text-color-on-secondary', style.sidemenuTextColorOnSecondary, 'var(--black-color)');

        if (style.noSearchboxGradient) {
            builder.addStyle(
              '.search-bar-container',
              'background-image: none !important; background-color: var(--primary-color) !important'
            );
        }
    }

    if (clientConfig.dashboardImgUrl) {
        builder.addStyleVar('--dashboard-bg', `url(${clientConfig.dashboardImgUrl})`);
    }

    return builder.build();
};

class StyleBuilder {
    styleVars = [];
    styles = [];

    addStyleVar = (varName, value) => {
        this.styleVars[varName] = value;
    };

    addStyle = (selector, value) => {
        this.styles[selector] = value;
    };

    addColorVar = (varName, value, def) => {
        let v = def;

        if (/^#\w{3}(\w{3})?$/.test(value)) {
            v = value;
        }

        this.styleVars[varName] = v;
    };

    build = () => {
        let rootBody = '';
        let styles = '';

        for (let varName in this.styleVars) {
            rootBody += `${varName}: ${this.styleVars[varName]};`;
        }

        for (let selector in this.styles) {
            styles += `body ${selector} { ${this.styles[selector]} }`;
        }

        return `:root body {${rootBody}} ${styles}`;
    };
}

const lightenColor = (color, percent) => {
    color = color.replace('#', '');
    var num = parseInt(color,16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;

    return '#' + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);

};

import { combineActions, handleActions } from 'redux-actions';
import {
    AUTOCOMPLETE_LIST_FULFILLED,
    CLEAR_FILTERS,
    CLEAR_SEARCH_QUERY,
    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_SUCCESS,
    SELECT_E_FORMS_ONLY,
    SELECT_SINGLE_CATEGORY,
    SELECT_SINGLE_KEYWORD,
    SELECT_SINGLE_TYPE,
    SELECT_SINGLE_TYPE_MOBILE,
    SET_PAGE,
    SET_PAGINATION,
    UPDATE_SEARCH_QUERY,
} from './constants';
import { tabGroupData } from '../../constants/data';

import { filter, get, includes, map } from 'lodash';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
    searchQuery: '',
    noResultsReason: '',
    articles: [],
    keywords: [],
    categories: [],
    types: tabGroupData(),
    selectedTypeName: '',
    selectedCategoryNames: [],
    selectedKeywordNames: [],
    loading: true,
    total: 0,
    page: 1,
};

const searchReducer = handleActions(
    {
        [combineActions(SEARCH_RESULTS_REQUEST, SEARCH_RESULTS_SUCCESS)]: (
            state,
            { type, payload }
        ) => {
            let categories = payload
                ? map(payload.categories, category => {
                      if (
                          includes(state.selectedCategoryNames, category.name)
                      ) {
                          return { ...category, isSelected: true };
                      }
                      return category;
                  })
                : [];
            let keywords = payload
                ? map(payload.keywords, keyword => {
                      if (includes(state.selectedKeywordNames, keyword.name)) {
                          return { ...keyword, isSelected: true };
                      }
                      return keyword;
                  })
                : [];

            return {
                ...state,
                articles:
                    type === SEARCH_RESULTS_REQUEST ? [] : payload.articles,
                keywords,
                categories,
                count: type === SEARCH_RESULTS_REQUEST ? 0 : payload.total,
                loadingResults: type === SEARCH_RESULTS_REQUEST ? true : null,
            };
        },
        [SET_PAGINATION]: (state, { payload }) => {
            return {
                ...state,
                total: payload,
            };
        },
        [SET_PAGE]: (state, { payload }) => {
            return {
                ...state,
                page: payload + 1,
            };
        },
        [UPDATE_SEARCH_QUERY]: (state, { payload }) => {
            return {
                ...state,
                searchQuery: payload,
                loading: true,
            };
        },
        [CLEAR_SEARCH_QUERY]: state => {
            return {
                ...state,
                searchQuery: '',
                autocompleteList: [],
            };
        },
        [SELECT_E_FORMS_ONLY]: (state, { payload }) => {
            return {
                ...state,
                eFormsOnly: payload,
            };
        },
        [AUTOCOMPLETE_LIST_FULFILLED]: (
            state,
            { payload: { articles, noResultsReason } }
        ) => {
            return {
                ...state,
                autocompleteList: articles,
                noResultsReason,
                loading: false,
            };
        },
        [SELECT_SINGLE_CATEGORY]: (
            state,
            { payload: { isSelected, name } }
        ) => {
            let categories = map(state.categories, category => {
                if (category.name === name) {
                    return { ...category, isSelected };
                }
                return category;
            });
            return {
                ...state,
                categories,
                selectedCategoryNames: map(
                    filter(categories, 'isSelected'),
                    ({ name }) => name
                ),
            };
        },
        [SELECT_SINGLE_TYPE]: (state, { payload: { value } }) => {
            let types = map(state.types, type => {
                if (type.value === value) {
                    return { ...type, isSelected: true };
                }
                return { ...type, isSelected: false };
            });
            return {
                ...state,
                selectedTypeName: value,
                types,
                page: 0,
            };
        },
        [SELECT_SINGLE_TYPE_MOBILE]: (state, { payload }) => {
            let types = map(state.types, type => {
                if (type.value === payload) {
                    return { ...type, isSelected: true };
                }
                return { ...type, isSelected: false };
            });
            return {
                ...state,
                selectedTypeName: payload,
                types,
            };
        },
        [SELECT_SINGLE_KEYWORD]: (state, { payload: { isSelected, name } }) => {
            let keywords = map(state.keywords, keyword => {
                if (keyword.name === name) {
                    return { ...keyword, isSelected };
                }
                return keyword;
            });
            return {
                ...state,
                keywords,
                selectedKeywordNames: map(
                    filter(keywords, 'isSelected'),
                    ({ name }) => name
                ),
            };
        },
        [CLEAR_FILTERS]: state => {
            let clearedCategories = map(state.categories, category => {
                return { ...category, isSelected: false };
            });
            let clearedKeywords = map(state.keywords, keyword => {
                return { ...keyword, isSelected: false };
            });
            return {
                ...state,
                categories: clearedCategories,
                keywords: clearedKeywords,
                selectedCategoryNames: [],
                selectedKeywordNames: [],
                selectedTypeName: '',
                page: 1,
            };
        },
        [LOCATION_CHANGE]: (state, { payload }) => {
            const pathname = get(payload, 'location.pathname', '');
            return pathname === '/'
                ? {
                      ...state,
                      searchQuery: '',
                      autocompleteList: [],
                  }
                : state;
        },
    },
    initialState
);

export default searchReducer;

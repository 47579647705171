import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppHeader } from './components/AppHeader';
import { AppFooter } from './components/AppFooter';

import Dashboard from './pages/Dashboard';
import SearchResults from './pages/SearchResults';
import Categories from './pages/Categories';
import ServiceCard from './pages/ServiceCard';
import SubCategories from './pages/SubCategories';
import Page404 from './pages/Page404';
import history from './history';
import { Router } from 'react-router';
import { buildCategoryPath, buildServiceCardPath, buildSubcategoryPath, } from './services/path-builders';
import { getClientConfig } from './features/service/actions';
import { connect } from 'react-redux';
import { applyConfig, ClientConfig } from './clientConfigApplicator';
import { bool, func, number, object } from 'prop-types';
import ServiceLoader from './pages/ServiceLoader';
import GoogleFontLoader from 'react-google-font-loader';
import ServiceDeactivated from "./pages/ServiceDeactivated";

const PureRoutes = ({
    getClientConfig,
    clientConfig,
    selectedBaseFontSize,
    serviceDeactivated
}) => {
    useEffect(() => {
        getClientConfig(process.env.REACT_APP_HOST || window.location.hostname);
    }, [getClientConfig]);

    if (!clientConfig) {
        return <ServiceLoader />;
    }

    applyConfig(clientConfig);
    applyBaseFontSize(selectedBaseFontSize);

    const config = ClientConfig;

    return (
        <Router history={history}>
            <GoogleFontLoader
                fonts={[
                    'Open Sans',
                    'Poppins',
                    config.style.titleFont,
                    config.style.textFont,
                ].map(font => {
                    return {
                        font: font
                            .split(',')[0]
                            .replace(/['"]/g, '')
                            .trim(),
                        weights: [400, 700],
                    };
                })}
            />
            <div className="content">
                <AppHeader
                    logoSrc={config.serviceLogoUrl}
                    clientShortName={config.shortName}
                    bipUrl={config.bipUrl}
                    logoUrl={config.logoUrl}
                    homePageUrl={config.homePageUrl}
                    serviceLogoUrl={config.serviceLogoUrl}
                    serviceName={config.serviceName}
                    selectedBaseFontSize={selectedBaseFontSize}
                />
                <Switch>
                    <Route
                        path="/search-results"
                        component={SearchResults}
                        history={history}
                    />

                    {
                        !(serviceDeactivated || !clientConfig.serviceActive)
                            ? routesForActiveService()
                            : <Route path="*" component={ServiceDeactivated} />
                    }



                </Switch>
            </div>
            <AppFooter
                logoSrc={config.logoUrl}
                copyrightYear={config.copyrightYear}
                clientShortName={config.shortName}
                clientFullName={config.fullName}
                clientHomePageUrl={config.homePageUrl}
            />
        </Router>
    );
};

PureRoutes.propTypes = {
    clientConfig: object,
    getClientConfig: func.isRequired,
    selectedBaseFontSize: number.isRequired,
    serviceDeactivated: bool.isRequired,
};

export const Routes = connect(
    state => {
        return {
            serviceDeactivated: state.service.deactivated || false,
            clientConfig: state.service.clientConfig,
            selectedBaseFontSize: Number(state.service.selectedBaseFontSize),
        };
    },
    {
        getClientConfig,
    }
)(PureRoutes);


const applyBaseFontSize = no => {
    const htmlNode = document.getElementsByTagName('html')[0];
    htmlNode.className = `font-size-${no}`;
};


const routesForActiveService = () => {
    return (
        <Switch>

            <Route path="/" exact component={Dashboard} />

            <Route
                path={buildCategoryPath(':id')}
                component={Categories}
            />
            <Route
                path={buildServiceCardPath(':id')}
                component={ServiceCard}
            />
            <Route
                path={buildSubcategoryPath(
                    ':categoryID',
                    ':subCategoryID'
                )}
                component={SubCategories}
            />
            <Route path="/article-not-found" component={Page404} />
            <Route path="*" component={Page404} />
        </Switch>
    );
}

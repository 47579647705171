import { array, func } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getCategories,
    getCategoriesListId,
} from '../../features/service/actions';
import { dashboardSelector } from '../../features/service/selectors';

import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { ServiceCardCategories } from './components/ServiceCardCategories';
import DashboardSearch from './components/DashboardSearch';
import { ClientConfig } from '../../clientConfigApplicator';

import fundsEU from './components/banners/funds_eu.svg';
import flagPL from './components/banners/flag_pl.svg';
import flagEU from './components/banners/flag_eu.svg';

import './components/banners/styles.scss';
import SVG from 'react-inlinesvg';

const Dashboard = ({ categories, getCategories }) => {
    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <React.Fragment>
            <Container wide={true}>
                <DashboardSearch />
            </Container>
            <Container>
                <Title title="Wybierz spośród kategorii" className="p-2" />
            </Container>
            <Container>
                <ServiceCardCategories
                    categories={categories}
                    getCategoriesListId={getCategoriesListId}
                />
            </Container>
            {ClientConfig.euBanner && (
                <Container>
                    <SVG src={fundsEU} className="banner col-12 col-md-4" />
                    <SVG src={flagPL} className="banner col-12 col-md-4" />
                    <SVG src={flagEU} className="banner col-12 col-md-4" />
                </Container>
            )}
        </React.Fragment>
    );
};

Dashboard.propTypes = {
    getCategories: func.isRequired,
    categories: array.isRequired,
};

export default connect(
    state => ({ ...dashboardSelector(state) }),
    {
        getCategories,
        getCategoriesListId,
    }
)(Dashboard);

import { bool, func, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const Checkbox = ({
    label,
    checked,
    id,
    name,
    disabled,
    onChecked,
    className,
    icon
}) => {
    return (
        <label
            className={classNames('checkbox', className, {
                'checkbox--disabled': disabled,
            })}
        >
            {icon &&
                <span className='checkbox__label'>
                    <img className='checkbox__label__icon' src={icon} alt='' />
                    <span className='checkbox__label__text'>{label}</span>
                </span>

            }

            {!icon && label}

            <input
                className="checkbox__input"
                type="checkbox"
                checked={checked}
                id={id}
                name={name}
                onChange={onChecked}
                disabled={disabled}
            />
            <span className="checkmark" />
        </label>
    );
};

Checkbox.propTypes = {
    id: string,
    name: string,
    onChecked: func.isRequired,
    checked: bool,
    label: string,
    className: string,
    icon: string,
    disabled: bool,
};

Checkbox.defaultProps = {
    checked: false,
    disabled: false,
};

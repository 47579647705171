const prefix = '[SEARCH]';

export const UPDATE_SEARCH_QUERY = `${prefix} UPDATE_SEARCH_QUERY`;
export const SELECT_SINGLE_CATEGORY = `${prefix} SELECT_SINGLE_CATEGORY`;
export const SELECT_SINGLE_KEYWORD = `${prefix} SELECT_SINGLE_KEYWORD`;
export const SELECT_SINGLE_TYPE = `${prefix} SELECT_SINGLE_TYPE`;
export const SELECT_SINGLE_TYPE_MOBILE = `${prefix} SELECT_SINGLE_TYPE_MOBILE`;
export const CLEAR_SEARCH_QUERY = `${prefix} CLEAR_SEARCH_QUERY`;
export const AUTOCOMPLETE_LIST_FULFILLED = `${prefix} AUTOCOMPLETE_LIST_FULFILLED`;
export const SEARCH_RESULTS_REQUEST = `${prefix} SEARCH_RESULTS_REQUEST`;
export const SELECT_E_FORMS_ONLY = `${prefix} SELECT_E_FORMS_ONLY`;
export const SEARCH_RESULTS_SUCCESS = `${prefix} SEARCH_RESULTS_SUCCESS`;
export const CLEAR_FILTERS = `${prefix} CLEAR_FILTERS`;
export const SET_PAGINATION = `${prefix} SET_PAGINATION`;
export const SET_PAGE = `${prefix} SET_PAGE`;
